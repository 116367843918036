import { t } from "i18next";

export const alphaNumericRegex = /^[0-9A-Za-z\s]+$/;
export const alphaNumericWithMarksRegex = /^[0-9A-Za-z-+/*%.,:;!?()\s]+$/;
export const nameRegex =
    /^[!@#$%^&*()`~\-_+=\[\]{}\\|;:'",<.>\?éàèùçâêîôûëïüÉÀÈÙÇÂÊÎÔÛËÏÜA-Za-z0-9\/ ]+$/;
export const nameWildCharRegex =
    /^(?:%?[!@#$%^&*()`~\-_+=\[\]{}\\|;:'",<.>\?éàèùçâêîôûëïüÉÀÈÙÇÂÊÎÔÛËÏÜA-Za-z0-9\/ ]+%?)$/;

export const transactionStatusMap = new Map([
    ["A", t("erps.common.status.recallApproved")],  ["B", t("erps.common.status.pendingApproval")],
    ["C", t("erps.common.status.recallCancelled")], ["D", t("erps.common.status.originalPaymentProcessed")],
    ["E", t("erps.common.status.expired")],         ["F", t("erps.common.status.processingCompleted")],
    ["N", t("erps.common.status.toBeProcessed")],   ["Q", t("erps.common.status.toBeProcessed")],
    ["R", t("erps.common.status.recallRejected")],  ["W", t("erps.common.status.waitingForAck")],
    ["X", t("erps.common.status.paymentFailed")],   ["Z", t("erps.common.status.rejectInitiated")]]);
