import axios from "axios";
import recallPaymentCustomerTypes from "../actions/types/recallPaymentCustomer.action.type";
import store from "../store/index";
import { getExceptionMessage, API_BASE_URL, formatDate } from "../utils/helper";
import moment from "moment";
import axiosRetry from 'axios-retry';

export const recallPaymentCustomerService = {
  getRPC,
};

function getRPC(transactionData, props) {
  return (dispatch) => {
    const user = store.getState()?.loginUserReducer.user;
    const fiDetail = store.getState()?.fiReducer;
    const { startOffset, endOffset, paymentFromDate, paymentToDate, ...rest } =
      transactionData;
    const updatedPaymentFromDate = moment(paymentToDate).format();
    const updatedPaymentToDate = moment(paymentToDate).format();
    const token = store.getState()?.loginUserReducer.token;

    axiosRetry(axios, {
      retries: 5,
      retryDelay: (retryCount) => {
        if (retryCount < 5) {
          if (props?.setErrorMessage && props?.t) {
            props?.setErrorMessage(
              props?.t("erps.transactionEnquiry.page.pending")
            );
          }
        }

        return retryCount * (retryCount < 2 ? 20000 : 30000); // time interval between retries
      },
      retryCondition: (error) => {
        return error.response.status === 504;
      },
      onRetry: (retryCount, error, requestConfig) => {
        const requestId = error?.response?.data?.message?.split("::")[0]?.trim();
        requestConfig.data = requestConfig.data?.replace('"requestId":null', `"requestId":"${requestId}"`);
      }
    });

    axios
      .post(
        `${API_BASE_URL()}/searchRPC`,
        {
          sortBy: rest.sortBy,
          billerId: rest.billerId,
          billerName: rest.billerName,
          transactionToAmount: rest.transactionToAmount,
          verificationNumber: rest.verificationNumber,
          paymentFromDate: formatDate(updatedPaymentFromDate),
          paymentToDate: formatDate(updatedPaymentToDate),
          fiCustomerNumber: rest.fiCustomerNumber,
          fiCustomerName: rest.fiCustomerName,        
          fiNumber: props?.fiNumberValue,
          selectedFiNumber: fiDetail?.selectedFi,
          fiCompanyNumber: transactionData?.subscribeEntityId
            ? transactionData.subscribeEntityId
            : user.fiCompanyNumber,
          offset: startOffset,
          limit: 100,
          userId: user.userId,
          languageCode: user.languageCode,
          roleType: user?.role?.role_type,
          requestId: null,
          requestFrom: "recallPaymentForCustomers",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        if (props?.setLoading) {
          props?.setLoading(false);
        }
        if (props?.setErrorMessage) {
          props?.setErrorMessage(null);
        }
        if (props?.setBtnLoading) {
          props?.setBtnLoading(false);
        }
        const response = {
          data: res.data,
          rpcDetails: {
            ...transactionData,
            paymentFromDate,
            paymentToDate,
          },
        };
        dispatch(success(response));
      })
      .catch((error) => {
        if (props?.setLoading) {
          props?.setLoading(false);
        }
        if (props?.setBtnLoading) {
          props?.setBtnLoading(false);
        }
        let errorMessage = getExceptionMessage(error, props?.t);
        if (props?.setErrorMessage) {
          props?.setErrorMessage(errorMessage);
        }
        dispatch(failure(errorMessage));
      });

    function success(data) {
      return {
        type: recallPaymentCustomerTypes.GET_RPC_SUCCESS,
        data,
      };
    }

    function failure(error) {
      return {
        type: recallPaymentCustomerTypes.GET_RPC_ERROR,
        error,
      };
    }
  };
}