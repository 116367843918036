import React, { useRef, useEffect, useState } from "react";
import { withSidebar } from "../hoc/withSidebar";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../actions";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { Box } from "@material-ui/core";
import CommonContainerBox from "../custom/containers/CommonContainerBox";
import ERPSGrid from "../custom/containers/ERPSGrid";
import CardList from "../custom/containers/cardList";
import ErrorMessage from "../custom/containers/ErrorMessage";
import { recallPendingApprovalsHeading } from "../../constants/RecallPendingApprovalsConstant";
import { getComponentAccess } from "../../utils/helper";


const RecallPendingApprovals = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {
        history,
        location: { state },
    } = props;

    const [errorMessage, setErrorMessage] = useState(null);
    const user = useSelector((state) => state.loginUserReducer.user);
    const rpaResponse = useSelector(
        (state) => state.transactionEnquiryReducer
    );

    const [transactionData, setTransactionData] = useState(  rpaResponse?.recallPendingApprovalsData || null);

    const [loading, setLoading] = useState(false);
    const [loadingMsg, setLoadingMsg] = useState("");
    const [pageNumber, setPage] = useState(0);
    const [rowPerPage, setRowPerPage] = useState(100);

    const [searchValues, setSearchValues] = useState({
        startOffset: 0,
        endOffset: rowPerPage,
        limit: rowPerPage,
    });

    const errorRef = useRef();
    useEffect(() => {
        if (errorRef.current) {
            errorRef.current.focus();
        }
    }, [errorMessage]);


    useEffect(() => {

        if (typeof actions.transactionEnquiryActions === 'undefined') {
            console.log(ts() + " useEffect:  actions.transactionEnquiryActions is undefined");
        } else {
            loadResults("from useEffect[searchType]");
        }
    }, [searchValues]);

    useEffect(() => {
        if (rpaResponse) {
            const responseData = rpaResponse.recallPendingApprovalsData?.data;
            console.log(responseData)
            if (responseData && responseData?.length) {
                for(let i=0; i < responseData.length; i++)
                    responseData[i].id = i + 1 + searchValues.startOffset;
            }
            setTransactionData(responseData);
        }
    }, [rpaResponse]);

    const paginateURR = (values) => {
        searchValues.startOffset = values.startOffset;
        searchValues.endOffset = values.endOffset;

        if (searchValues.limit !== (values.endOffset - values.startOffset))
            searchValues.limit = values.endOffset - values.startOffset;

        loadResults();
    };

    let loadResults = (tempText = "") => {
        const actualCriteria = { ...searchValues };

        if (errorMessage)
            setErrorMessage(null);

        if (loading) {
            setLoading(false);
            setLoadingMsg(null);
        }
        dispatch(
            actions.transactionEnquiryActions.searchRecallPendingApprovals(
                actualCriteria,
                { ...props, t, history, setLoading, setErrorMessage }
            )
        );
    };

    return (
        <div role="main">
            <Box sx={{ ml: 3 }}>
                {!errorMessage ? ("") :
                    (<Box className="manageUsers__errorMsg__container" role="alert" mt={errorMessage ? 2 : 0} >
                        {<ErrorMessage error={errorMessage} errorRef={errorRef} />}
                    </Box>)
                }

                <Box className="manageUsers__page__title">
                    {/* For Title update */}
                    <CommonContainerBox title={t("erps.recallpendingapprovals.page.title")} />
                    <Typography variant="h1">{t("erps.recallpendingapprovals.page.title")}</Typography>
                </Box>


                {transactionData !== null && transactionData !== undefined && transactionData.length > 0 ? (
                        <>
                            <Box className="manageUsers_table_visible">
                                <ERPSGrid
                                    data={{
                                        rows: transactionData,
                                        columns: recallPendingApprovalsHeading(
                                        ),
                                        count: rpaResponse?.recallPendingApprovalsData?.count,
                                    }}
                                    onAnyPaginationAction={paginateURR}
                                    pageNumber={pageNumber}
                                    setPage={setPage}
                                    rowPerPage={rowPerPage}
                                    tableLabelText={"_Page Title_"}
                                    hideFooterPagination={false}
                                    pageName={"transaction-enquiry-rpa"}
                                />
                            </Box>
                            <Box className="manageUsers_card_visible">
                                <CardList
                                    data={{
                                        rows: transactionData,
                                        columns: recallPendingApprovalsHeading(
                                        ),
                                    }}
                                    hideFooterPagination={false}
                                    pageName={"recall-pending-approvals"}
                                    pageNumber={pageNumber}
                                    setPage={setPage}
                                    rowPerPage={rowPerPage}
                                />
                            </Box>
                        </>
                    )
                    : ("")
                }
                {(transactionData === null || transactionData === undefined) && (
                    <Box mt={1}>
                        <Typography variant="body1">
                            {" "}
                            {}
                            {"Search not started"}
                        </Typography>
                    </Box>
                )}
                {Array.isArray(transactionData) && transactionData?.length === 0 && (
                    <Box mt={1}>
                        <Typography variant="body1">
                            {" "}
                            {}
                            {`0 ${"_resultfound_"}`}
                        </Typography>
                    </Box>
                )}

            </Box>
        </div>
    );
};

export default withSidebar(RecallPendingApprovals);

function ts() {  return new Date().toISOString().substring(11, 23);  }
