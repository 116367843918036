import { t } from "i18next";
import store from "../store/index";
import BMOEnglish from "../assets/agreementFiles/BMO-English.pdf";
import BMOFrench from "../assets/agreementFiles/BMO-French.pdf";
import {url} from "../constants/url.constants";
import BNSEnglish from "../assets/agreementFiles/BNS-English.pdf";
import BNSFrench from "../assets/agreementFiles/BNS-French.pdf";

import CIBCEnglish from "../assets/agreementFiles/CIBC-English.pdf";
import CIBCFrench from "../assets/agreementFiles/CIBC-French.pdf";

import NonBMOEnglish from "../assets/agreementFiles/NonBMO-English.pdf";
import NonBMOFrench from "../assets/agreementFiles/NonBMO-French.pdf";
import moment from "moment";

export const getComponentAccess = (module, loginUser) => {
  const data = [];
  loginUser.role.modules.forEach((item) => {
    if (module.label_type_code === item.componentType) {
      if (item.childComponents.length) {
        item.childComponents.forEach((fun) => {
          if (module.function_type_code === fun.componentType) {
            data.push(...fun.childComponents);
          }
        });
      }
    }
  });
  return data;
};
const minTwoDigits = (n) => (n < 10 ? "0" : "") + n;

const getDateInStringFormat = (format, fileDate) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const currentDate = fileDate ? new Date(fileDate) : new Date();
  const year = currentDate.getFullYear();
  const month = minTwoDigits(currentDate.getMonth() + 1);
  const date = minTwoDigits(currentDate.getDate());
  const monthName = monthNames[month - 1];
  if (format === "DDMMYYYY") {
    return `${date}${month}${year}`;
  } else if (format === "YYYYMMDD") {
    return `${year}${month}${date}`;
  } else if ("MmmYYYY") {
    return `${monthName}${year}`;
  }
};

export const getFileNameToDownload = (page, roleId, data) => {
  let fileDate = "";
  if (data?.file_date) {
    fileDate = data?.file_date;
  } else if (data?.reportDate) {
    fileDate = data?.reportDate;
  }
  if (page === "report") {
    const webportalRoles = [1, 2, 3, 4];
    if (webportalRoles.includes(roleId)) {
      const fileName = data?.reportDescription || data?.file_type;
      const parsedFileName =
        fileName && fileName.includes("NBCAU ")
          ? fileName?.replace("NBCAU ", "")
          : fileName;

      let updatedFileName = parsedFileName?.replaceAll(" ", "_");
      const fileFormat =
        data?.downloaded_file_format?.toLowerCase() === "pdf" ? "pdf" : "csv";
      if (
        fileName.includes("Monthly") ||
        fileName?.trim() === "Biller Exception Report" ||
        fileName?.trim() === "Monthly Billing Volume File"
      ) {
        updatedFileName = `${updatedFileName}-${getDateInStringFormat(
          "MmmYYYY",
          fileDate
        )}.${fileFormat}`;
      } else {
        updatedFileName = `${updatedFileName}-${getDateInStringFormat(
          "DDMMYYYY",
          fileDate
        )}.${fileFormat}`;
      }
      return updatedFileName;
    } else {
      const fileName = data?.reportName;
      const fileType = data?.downloaded_file_format?.toLowerCase();
      const fileFormat =
        fileType === "pdf" ? "pdf" : fileType === "txt" ? "txt" : "csv";
      const updatedFileName = `${fileName}_${getDateInStringFormat(
        "YYYYMMDD",
        fileDate
      )}.${fileFormat}`;
      return updatedFileName;
    }
  } else {
    let fileName = data.file_type;
    const fileNumber =
      fileName && fileName !== "Service Fee Invoice" ? data?.file_number : "";
    const fileFormat = data?.fileFormat === "PDF" ? "pdf" : "csv";
    let updatedFileName = fileName?.replaceAll(" ", "_");
    updatedFileName = fileNumber
      ? `${updatedFileName}_${getDateInStringFormat(
          "YYYYMMDD",
          fileDate
        )}_${fileNumber}.${fileFormat}`
      : `${updatedFileName}_${getDateInStringFormat(
          "YYYYMMDD",
          fileDate
        )}.${fileFormat}`;
    return updatedFileName;
  }
};

export function API_BASE_URL() {
  if (process.env.REACT_APP_ENV === "dev")
    return store.getState()?.appConfigReducer.data?.API_BASE_URL + "/rest";
  return "/rest";
}

export const getErrorMessageFromException = (error, translation) => {
  const errorResponse = error?.response?.data;
  if (errorResponse) {
    const statusCode = errorResponse?.statusCode;

    if (statusCode) {
      return translation("erps.error.code.500");
    }
  }
  return getExceptionMessage(error, translation);
};

export const customErrorAddBiller = (error, translation) => {
  const errorStatus = error?.response?.status;
  if (errorStatus === 500) {
    return translation("erps.billermanagement.addbiller.error.5xx");
  }
  return getExceptionMessage(error, translation);
};

export const customErrorAddBillerUser = (error, translation) => {
  const errorStatus = error?.response?.status;
  if (errorStatus == 500) {
    return error?.response?.data;
  }
  return getExceptionMessage(error, translation);
};

export const customErrorDuplicateEmailAddress = (error, translation) => {
  const errorStatus = error?.response?.status;
  if (errorStatus == 500) {
    return error?.response?.data;
  }
  return getExceptionMessage(error, translation);
};

export const getExceptionMessage = (error, translation) => {
  const statusCode = error.response?.status;
  let errorMessage = translation("erps.error.code.network");
  const errorRes = error?.response?.data;
  if (statusCode >= 500) {
    if (errorRes?.message) {
      if(errorRes.statusCode === 504){
        errorMessage = translation("erps.transactionEnquiry.search.failed");
      } else {
        errorMessage = errorRes.message;
      }
    } else {
      errorMessage = translation("erps.error.code.network");
    }
  } else if (statusCode == 403) {
    errorMessage = translation("erps.error.code.403");
  }else if (statusCode == 400 && errorRes.detail == "Not a BMO biller") {
    errorMessage = translation("erps.rejectpaymentmerchants.page.lable.notbmobiller");
  } else if (statusCode == 404) {
    errorMessage = translation("erps.error.code.404");
  } else if (statusCode == 401 || statusCode == 400) {
    let errorResponse = error?.response?.data?.error_description;
    if (statusCode == 401 && error?.response?.data?.error == "invalid_token") {
      errorResponse = translation("erps.error.message.sessionexpired");
    }
    if (errorResponse) {
      errorMessage = errorResponse;
    }
  }
  return errorMessage;
};

export const getServiceAgreement = (user) => {
  if (user) {
    const { fiNumber = 99, searchBillerFilesDto, creditorFiNumber } = user;
    const updatedCreditorFiNumber =
      creditorFiNumber || searchBillerFilesDto?.creditor_fi_number;
    if (
      fiNumber === 99 &&
      updatedCreditorFiNumber &&
      updatedCreditorFiNumber === 1
    ) {
      return { english: BMOEnglish, french: BMOFrench, fileName: "BMO" };
    } else if (
      fiNumber === 99 &&
      updatedCreditorFiNumber &&
      updatedCreditorFiNumber !== 1
    ) {
      return {
        english: NonBMOEnglish,
        french: NonBMOFrench,
        fileName: "BMO",
      };
    } else if (fiNumber === 2) {
      return { english: BNSEnglish, french: BNSFrench, fileName: "BNS" };
    } else if (fiNumber === 10) {
      return { english: CIBCEnglish, french: CIBCFrench, fileName: "CIBC" };
    }
  }
};

export const getFormatDetails = (data) => {
  if (data) {
    const updateValue = typeof data !== "string" ? data.toString() : data;
    return `(${updateValue.slice(0, 3)}) ${updateValue.slice(
      3,
      6
    )}-${updateValue.slice(6)}`;
  }
};

export const getFormattedPhoneNumber = (phone) => {
  if (phone) {
    const updatedString = typeof phone !== "string" ? phone.toString() : phone;
    const formattedPhoneNumber = `${updatedString.slice(
      0,
      3
    )}-${updatedString.slice(3, 6)}-${updatedString.slice(6)}`;
    return formattedPhoneNumber;
  }
  return "";
};

export const getNextFilterId = (
  filteredQueueDataList,
  requiredArr,
  userId,
  key
) => {
  let nextFilteredId = "";
  if (requiredArr && requiredArr.length) {
    let nextIndexVal = requiredArr.findIndex(
      ({ updatedBy }) => userId !== updatedBy || userId == updatedBy
    );
    if (nextIndexVal === -1) {
      if (filteredQueueDataList && filteredQueueDataList.length) {
        nextIndexVal = filteredQueueDataList.findIndex(
          ({ updatedBy }) => userId !== updatedBy || userId == updatedBy
        );
        if (nextIndexVal !== -1) {
          nextFilteredId = filteredQueueDataList[nextIndexVal]?.[key];
        }
      }
    } else {
      nextFilteredId = requiredArr[nextIndexVal]?.[key];
    }
  }
  return nextFilteredId;
};

export var lpad = (number, length) => {
  var str = "" + number;
  if (str.length < length) str = new Array(length - str.length).join("0") + str;
  return str;
};

export const formatDate = (dateValue) => {
  if (dateValue) {
    const d = dateValue;
    if (typeof d === "string") {
      return d.split("T")[0]?.replaceAll("-", "");
    } else {
      return d.toISOString()?.split("T")[0]?.replaceAll("-", "");
    }
  }
};

export const requiredFormatDate = (d) => {
  if (d) {
    const splittedDate = d.split("/");
    if (splittedDate && splittedDate.length) {
      let [date, month, year] = splittedDate;
      if (year.split(" ")) {
        year = year.split(" ")[0];
      }
      return `${year}-${month}-${date}`;
    }
  }
};

export const statisticsFormatDate = (d) => {
  if (d) {
    return moment(d, "YYYYMMDD").format("YYYY-MM-DD");
  }
};

export const formatDateTime = (d) => {
  if (d) {
    return moment(d).format("YYYY-MM-DD hh:mm:ss");
  } else {
    return "";
  }
};

export const convertDateStringIntoERPSDateFormat = (dateStr) => {
  if (dateStr) {
    var date = formatDate(dateStr);
    if (date) {
      return date.replace(/(\d{4})(\d{2})(\d{2})/g, "$1-$2-$3");
    }
  }
};

export const convertDateIntoERPSDateFormat = (date) => {
  if (date) {
    return date.toString()?.replace(/(\d{4})(\d{2})(\d{2})/g, "$1-$2-$3");
  }
};

export const getDateFromString = (d) => {
  if (d && typeof d === "number") {
    const parsedValue = d.toString();

    if (parsedValue && parsedValue.length) {
      const formattedDate = `${parsedValue.slice(0, 4)}/${parsedValue.slice(
        4,
        6
      )}/${parsedValue.slice(6)}`;
      const date = new Date(formattedDate);
      return date;
    }
  }
};

export const roleConfigUrls = {
  1: "/manage-billers",
  2: "/manage-billers",
  3: "/dashboard",
  4: "/dashboard",
  5: "/manage-billers",
  6: "/manage-users",
  8: "/review-transactions",
  9: "/review-transactions",
  10: "/manage-billers",
  11: "/manage-users",
  12: "/manage-billers",
};

let USDollar = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const formatAmount = (amount) => {
  return USDollar.format(amount);
};

export const zeroPad = (num, places) => {
  if (num) {
    var zero = places - num.toString().length + 1;
    return Array(+(zero > 0 && zero)).join("0") + num;
  } else {
    return "";
  }
};
export const validateURL = (link) => {
  const res = link.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9@:%_\+.~#?&=\/,;'"]*/g
  );
  return res !== null;
};

const hasUndefined = (val) => {
  return val === undefined;
};

const hasArray = (arr) => {
  return !hasUndefined(arr) && Array.isArray(arr);
};
const hasObject = (obj) => {
  return (
    !hasUndefined(obj) &&
    typeof obj === "object" &&
    !Array.isArray(obj) &&
    obj !== null
  );
};
const hasStringValue = (val) => {
  return !hasUndefined(val) && val !== null && typeof val === "string";
};
const hasNumericValue = (val) => {
  return !hasUndefined(val) && typeof val === "number" && !isNaN(val);
};
const hasBooleanValue = (val) => {
  return !hasUndefined(val) && typeof val == "boolean";
};

const hasNullType = (val) => {
  return !hasUndefined(val) && typeof val == "object" && val === null;
};

const compareTwoArrayOfObjects = (
  first_array_of_objects,
  second_array_of_objects
) => {
  return (
    first_array_of_objects.length === second_array_of_objects.length &&
    first_array_of_objects.every((element_1) =>
      second_array_of_objects.some((element_2) =>
        Object.keys(element_1).every((key) => element_1[key] === element_2[key])
      )
    )
  );
};

const hasEmptyValue = (val) => {
  return val === undefined || val === "";
};
const checkObjectValueChange = (preObj, postObj) => {
  let hasNotChanged = true;
  for (let keyObj in preObj) {
    if (!hasObject(preObj[keyObj])) {
      if (preObj[keyObj] !== postObj[keyObj]) {
        hasNotChanged = false;
        return;
      }
    } else {
      return checkObjectValueChange(preObj[keyObj], postObj[keyObj]);
    }
  }
  return hasNotChanged;
};

const checkArrayValueChange = (prevArr, currArr) => {
  const hasNotChanged = compareTwoArrayOfObjects(prevArr, currArr);
  return hasNotChanged;
};

const checkValueChanged = (oldData, newData, k) => {
  let hasValueChanged = false;
  if (hasArray(newData) && hasArray(oldData)) {
    hasValueChanged = checkArrayValueChange(oldData, newData);
  } else if (hasObject(oldData) && hasObject(newData)) {
    hasValueChanged = checkObjectValueChange(oldData, newData);
  } else if (hasStringValue(oldData) && hasStringValue(newData)) {
    const hasStringValueSame = oldData === newData;
    hasValueChanged = hasStringValueSame;
  } else if (hasNumericValue(oldData) && hasNumericValue(newData)) {
    hasValueChanged = oldData === newData;
  } else if (hasBooleanValue(oldData) && hasBooleanValue(newData)) {
    hasValueChanged = oldData === newData;
  } else if (hasNullType(oldData) && hasNullType(newData)) {
    hasValueChanged = oldData == newData;
  } else if (hasNullType(oldData) && hasStringValue(newData)) {
    hasValueChanged = oldData == newData;
  } else {
    return hasNullType(oldData) || hasEmptyValue(oldData);
  }
  return hasValueChanged;
};

export const checkFieldsChange = (pre, post) => {
  if (hasObject(pre) && hasObject(post)) {
    const postKeys = Object.keys(post);
    return postKeys.some((objKey) => {
      const val = checkValueChanged(pre[objKey], post[objKey], objKey);
      return !val;
    });
  }
  return false;
};
export const getLabelName = (data) => {
  const { key, hasLastActiveRecord, lastRecordDisable, t } = data;
  if (key === "decline") {
    return hasLastActiveRecord() || lastRecordDisable
      ? t("erps.updatebillerapproval.button.reject")
      : t("erps.updatebillerapproval.button.rejectandgonext");
  } else if (key == "approve") {
    return hasLastActiveRecord() || lastRecordDisable
      ? t("erps.updatebillerapproval.button.approve")
      : t("erps.updatebillerapproval.button.approveandgonext");
  }
  return "";
};

export const getBillerGroupErrorMessage = (message) => {
  if (message) {
    if (message === "ERPS0055") {
      return [t("erps.billermanagement.error.selectionIdNotexist")];
    } else if (message === "HSBCDUP") {
      return [t("erps.billermanagement.error.billerIdAlreadyExists")];
    }
  }

  return [];
};

export const sanitizeQueryParams = (jsonObj) => {
  let updatedReqBody;
  Object.keys(jsonObj).forEach((key) => {
    if (
      jsonObj[key] === undefined ||
      ("string" === typeof jsonObj[key] && jsonObj[key].trim() === "")
    ) {
      delete jsonObj[key];
    } else {
      if (typeof jsonObj[key] === "string") {
        updatedReqBody = { ...updatedReqBody, [key]: jsonObj[key].trim() };
      } else {
        updatedReqBody = { ...updatedReqBody, [key]: jsonObj[key] };
      }
    }
  });
  return updatedReqBody;
};

/** This function returns a comma-separated list of an object's properties;  with values if the 2nd argument is true. */
export const listProps = (obj, withValues = false) => {
  if (obj === undefined)  return "object is undefined";
  if (obj === null)  return "object is null";
  var s = "";
  if (withValues)
    for (var key in obj)  s += ", " + key + "=" + obj[key];
  else
    for (var key in obj)  s += ", " + key;
  return s.substring(2);
};
