import { RadioGroup, Radio, FormControl } from "@mui/material";
import { PropTypes } from "prop-types";
import MuiFormLabel from "@mui/material/FormLabel";
import MuiFormControlLabel from "@mui/material/FormControlLabel";
import styled from "@emotion/styled";
import { withERPSRadioGroupActionValidator } from "../../hoc/withERPSRadioGroupActionValidator";

const FormLabel = styled(MuiFormLabel)((props) => ({
  marginTop: props.marginTop ? "0.7rem !important" : "2rem !important",
  marginBottom: "8px !important",
  color: "#000000 !important",
}));

const FormControlLabel = styled(MuiFormControlLabel)((props) => ({
  marginRight: "2rem !important",
  fontFamily: "Roboto, Light",
  fontSize: "14px",
}));

function ERPSBaseRadioGroup(props) {
  const {
    label,
    value,
    onChange,
    items,
    disabled,
    marginTop,
    className,
    required,
    labelPlacement,
    row = true,
  } = props;
  return (
    <FormControl className={className}>
      <FormLabel marginTop={marginTop} required={required}>
        {label}
      </FormLabel>
      <RadioGroup
        row={row}
        name={label}
        value={value}
        onChange={onChange}
        className="newuser_radioGrp"
      >
        {items.map((item, index) => (
          <FormControlLabel
            value={item.value}
            control={<Radio />}
            label={item.name}
            disabled={item.disabled || disabled}
            labelPlacement={labelPlacement}
            key={index}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}

ERPSBaseRadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default withERPSRadioGroupActionValidator(ERPSBaseRadioGroup);
