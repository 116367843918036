import { combineReducers } from "redux";
import loginUserReducer, { loginUserInitialState } from "./login.user.reducer";
import serviceAgreementReducer, {
  serviceAgreementInitialState,
} from "./service.agreement.reducer";
import userDetailReducer, {
  userDetailInitialState,
} from "./user.detail.reducer";
import userRoleReducer, { userRoleInitialState } from "./user.role.reducer";
import userRoleTypeReducer, {
  userRoleTypeInitialState,
} from "./user.role.type.reducer";
import subscribingEntitiesReducer, {
  subscribingEntitiesInitialState,
} from "./subscribing.entity.reducer";
import billerProfileReducer, {
  billerProfileInitialState,
} from "./biller.profile.reducer";
import newUserReducer, { newUserInitialState } from "./new.user.reducer";
import budgetCategoryReducer, {
  budgetCategoryInitialState,
} from "./budget.category.reducer";
import batchHolidayReducer, {
  batchHolidayInitialState,
} from "./batch.holiday.reducer";
import forgetPasswordReducer, {
  forgetPasswordInitialState,
} from "./forget.password.reducer";
import changePasswordReducer, {
  changePasswordInitialState,
} from "./change.password.reducer";
import userPasswordReducer, {
  userPasswordInitialState,
} from "./user.password.reducer";
import userQueueReducer, { userQueueInitialState } from "./user.queue.reducer";
import userApprovalReducer, {
  userApprovalInitialState,
} from "./user.approval.reducer";
import billerApprovalReducer, {
  billerApprovalInitialState,
} from "./biller.approval.reducer";
import pricePlanReducer, { pricePlanInitialState } from "./price.plan.reducer";
import availableDocumentReducer, {
  availableDocumentInitialState,
} from "./available.document.reducer";
import pricePlanQueueReducer, {
  pricePlanQueueInitialState,
} from "./priceplan.queue.reducer";
import fiReducer, {
  financialInstitutionInitialState,
} from "./financial.institution.reducer";
import appConfigReducer, { appConfigInitialState } from "./app.config.reducer";
import loginActionTypes from "../actions/types/login.action.type";
import provinceReducer, { provinceInitialState } from "./province.reducer";

import deliveryMethodReducer, {
  deliveryMethodInitialState,
} from "./deliveryMethod.reducer";
import formatMethodReducer, {
  formatMethodInitialState,
} from "./format.method.reducer";
import transactionEnquiryReducer, {
  transactionEnquiryInitialState,
} from "./transaction.enquiry.reducer";
import relatedBillerReducer, {
  relatedBillerInitialState,
} from "./relatedBiller.reducer";
import marketingManagementReducer, {
  marketingManagementInitialState,
} from "./marketing.management.reducer";

import loginMessageManagementReducer, {
  loginMessageManagementInitialState,
} from "./login.message.reducer";
import viewStatisticsReducer, {
  viewStatisticsInitialState,
} from "./view-statstics.reducer";
import synchronizationReducer, {
  synchronizationInitialState,
} from "./synchronization.reducer";
import faxManagementReducer, {
  faxManagementInitialState,
} from "./fax.management.reducer";
import recallPaymentCustomerReducer, {
  recallPaymentCustomerInitialState,
} from "./recall.payment.customer.reducer";
const initReducer = combineReducers({
  loginUserReducer,
  budgetCategoryReducer,
  batchHolidayReducer,
  synchronizationReducer,
  serviceAgreementReducer,
  userDetailReducer,
  userRoleReducer,
  userRoleTypeReducer,
  newUserReducer,
  subscribingEntitiesReducer,
  forgetPasswordReducer,
  changePasswordReducer,
  userPasswordReducer,
  userQueueReducer,
  userApprovalReducer,
  pricePlanReducer,
  billerProfileReducer,
  availableDocumentReducer,
  pricePlanQueueReducer,
  billerProfileReducer,
  fiReducer,
  appConfigReducer,
  provinceReducer,
  deliveryMethodReducer,
  formatMethodReducer,
  billerApprovalReducer,
  transactionEnquiryReducer,
  relatedBillerReducer,
  marketingManagementReducer,
  loginMessageManagementReducer,
  viewStatisticsReducer,
  faxManagementReducer,
  recallPaymentCustomerReducer,
});

const rootReducer = (state, action) => {
  if (
    action.type === loginActionTypes.LOGOUT_SUCCESS ||
    action.type === loginActionTypes.LOGOUT_FAIL
  ) {
    const dashboardMessage =
      state?.loginMessageManagementReducer?.getDashboardMessage;
    const updatedLoginMessageManagementInitialState = {
      ...loginMessageManagementInitialState,
      getDashboardMessage: dashboardMessage,
    };
    state = {
      loginUserReducer: loginUserInitialState,
      budgetCategoryReducer: budgetCategoryInitialState,
      batchHolidayReducer: batchHolidayInitialState,
      serviceAgreementReducer: serviceAgreementInitialState,
      userDetailReducer: userDetailInitialState,
      userRoleReducer: userRoleInitialState,
      userRoleTypeReducer: userRoleTypeInitialState,
      newUserReducer: newUserInitialState,
      subscribingEntitiesReducer: subscribingEntitiesInitialState,
      forgetPasswordReducer: forgetPasswordInitialState,
      changePasswordReducer: changePasswordInitialState,
      userPasswordReducer: userPasswordInitialState,
      userQueueReducer: userQueueInitialState,
      userApprovalReducer: userApprovalInitialState,
      pricePlanReducer: pricePlanInitialState,
      billerProfileReducer: billerProfileInitialState,
      availableDocumentReducer: availableDocumentInitialState,
      pricePlanQueueReducer: pricePlanQueueInitialState,
      billerProfileReducer: billerProfileInitialState,
      fiReducer: financialInstitutionInitialState,
      appConfigReducer: state.appConfigReducer,
      provinceReducer: provinceInitialState,
      deliveryMethodReducer: deliveryMethodInitialState,
      formatMethodReducer: formatMethodInitialState,
      billerApprovalReducer: billerApprovalInitialState,
      transactionEnquiryReducer: transactionEnquiryInitialState,
      relatedBillerReducer: relatedBillerInitialState,
      marketingManagementReducer: marketingManagementInitialState,
      loginMessageManagementReducer: updatedLoginMessageManagementInitialState,
      viewStatisticsReducer: viewStatisticsInitialState,
      synchronizationReducer: synchronizationInitialState,
      faxManagementReducer: faxManagementInitialState,
      recallPaymentCustomerReducer: recallPaymentCustomerInitialState,
    };
  }
  return initReducer(state, action);
};

export default rootReducer;
