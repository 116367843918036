import React, { useRef, useEffect, useState } from "react";
import { withSidebar } from "../hoc/withSidebar";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../actions";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { Box } from "@material-ui/core";
import CommonContainerBox from "../custom/containers/CommonContainerBox";
import ERPSBaseRadioGroup from "../custom/inputs/ERPSBaseRadioGroup";
import ERPSGrid from "../custom/containers/ERPSGrid";
import CardList from "../custom/containers/cardList";
import ErrorMessage from "../custom/containers/ErrorMessage";
import { upcomingRecallsRejectsHeading } from "../../constants/UpcomingRecallsRejectsConstants";
import { getComponentAccess } from "../../utils/helper";


const UpcomingRecallsRejects = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    history,
    location: { state },
  } = props;

  const [errorMessage, setErrorMessage] = useState(null);
  const user = useSelector((state) => state.loginUserReducer.user);

  const componentAccess = state?.componentAccess
          || getComponentAccess( { function_type_code: "URR", label_type_code: "TE" }, user );

  const searchTypes = [
    { name: t("erps.transactionManagement.label.showCustomerRecalls"), value: "customerRecall" },
    { name: t("erps.transactionManagement.label.showMerchantRejects"), value: "merchantReject" },
  ];

  const urrResponse = useSelector(
    (state) => state.transactionEnquiryReducer
  );

  // Setting these two values from urrResponse if we're coming from the Detail Page (state?.fromDetailPag == true)
  const [searchType, setSearchType] = useState( state?.fromDetailPage && urrResponse?.upcomingRecallsRejectsDetails?.searchType || searchTypes[0].value);
  const [transactionData, setTransactionData] = useState( state?.fromDetailPage && urrResponse?.upcomingRecallsRejectsData || null);

  const [loading, setLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState("");
  const [pageNumber, setPage] = useState(0);
  const [rowPerPage, setRowPerPage] = useState(100);

  const [searchValues, setSearchValues] = useState({
    searchType: searchTypes[0].value,
    startOffset: 0,
    endOffset: rowPerPage,
    limit: rowPerPage,
  });

  const errorRef = useRef();
  useEffect(() => {
    if (errorRef.current) {
      errorRef.current.focus();
    }
  }, [errorMessage]);

  const searchTypeChanged = (searchTypeValue) => {
    if (loading) {
      // display msg
      setLoadingMsg("The page is loading, please wait");
    } else {
      // resetting back to the first page
      searchValues.startOffset = 0;
      searchValues.endOffset = rowPerPage;
      setSearchType(searchTypeValue);
    }
  };

  useEffect(() => {

    if (state?.fromDetailPage && urrResponse?.upcomingRecallsRejectsDetails /*&& urrResponse?.upcomingRecallsRejectsData*/) { // if back from the Details page
      // Above we have already set  searchType and transactionData.
      // The only thing we need to do is update the page number.

      // set searchValues' attriutes := urrResponse.upcomingRecallsRejectsDetails' attributes
      //1. this works
      searchValues.searchType = urrResponse.upcomingRecallsRejectsDetails.searchType
      searchValues.startOffset = urrResponse.upcomingRecallsRejectsDetails.startOffset;
      searchValues.endOffset = urrResponse.upcomingRecallsRejectsDetails.endOffset;

    //   //2. this is preferred but it didn't work
    //   setSearchValues({
    //     searchType: urrResponse.upcomingRecallsRejectsDetails.searchType,
    //     startOffset: urrResponse.upcomingRecallsRejectsDetails.startOffset,
    //     endOffset: urrResponse.upcomingRecallsRejectsDetails.endOffset,
    //     limit: urrResponse.upcomingRecallsRejectsDetails.limit
    //     //...rest
    // });

      const pageNum = searchValues.startOffset / (searchValues.endOffset - searchValues.startOffset);
      setPage(pageNum);
      state.fromDetailPage = null;  // resetting the coming-from-detail-page flag
    } else if (typeof actions.transactionEnquiryActions === 'undefined') {  // IS THERE A NEED TO CHECK ??
      console.log(ts() + " useEffect:  actions.transactionEnquiryActions is undefined");
    } else {
      searchValues.searchType = searchType;
      loadResults("from useEffect[searchType]");
    }
  }, [searchType]);

  // Handling Response
  useEffect(() => {     //  (*.., [urrResponse])  --called ONLY after a call to the service.  Actually, NOT SO!
    if (urrResponse) {
      const responseData = urrResponse.upcomingRecallsRejectsData?.data;
      if (responseData && responseData?.length) {
        // I UPDATE .id ATTRIBUTE DIRECTLY IN responseData INSTEAD OF CREATING NEW VAR updatedRes
        // Reason:  in RecallRejectDetails.js, we take transactionData (the list) from the reducer:  
        //          const transactionListData = useSelector( (state) => state.transactionEnquiryReducer?.upcomingRecallsRejectsData?.data );
        // So the direct updates to responseData are visible in the Details screen
        for(let i=0; i < responseData.length; i++)
          responseData[i].id = i + 1 + searchValues.startOffset;
      }
      setTransactionData(responseData);        
    }
  }, [urrResponse]);

  const paginateURR = (values) => {  // 'values' has fields startOffset and endOffset (for 1st Page: 0,25;  2nd Page: 25,50;  3rd: 50,75...)
    // Can this multi assignment be shortened??
    searchValues.startOffset = values.startOffset;
    searchValues.endOffset = values.endOffset;  // IS THIS NEEDED?

    if (searchValues.limit !== (values.endOffset - values.startOffset))
      searchValues.limit = values.endOffset - values.startOffset;

    loadResults();
  };

  let loadResults = (tempText = "") => {
    //const actualCriteria = sanitizeReqBody({ ...searchValues });
    const actualCriteria = { ...searchValues };
    actualCriteria.transactionStatus = (searchType === searchTypes[1].value) ? "Q" : "N";
    
    if (errorMessage)
      setErrorMessage(null);

    if (loading) {
      setLoading(false);
      setLoadingMsg(null);
    }
    dispatch(
      actions.transactionEnquiryActions.searchUpcomingRejectsRecalls(
        actualCriteria,
        { ...props, t, history, setLoading, setErrorMessage }
      )
    );
  };
  
  return (
    <div role="main">
      <Box sx={{ ml: 3 }}>
        {!errorMessage ? ("") :
          (<Box className="manageUsers__errorMsg__container" role="alert" mt={errorMessage ? 2 : 0} >
            {<ErrorMessage error={errorMessage} errorRef={errorRef} />}
          </Box>)
        }

        <Box className="manageUsers__page__title">
          {/* For Title update */}
          <CommonContainerBox title={t("erps.upcomingrecallsrejects.page.title")} />
          <Typography variant="h1">{t("erps.upcomingrecallsrejects.page.title")}</Typography>
        </Box>

        <Stack sx={{ mt: 2, spacing: 2 }} direction="row">

          <Box>
            <ERPSBaseRadioGroup
              name="searchBy"
              marginTop={"true"}
              //InputLabelProps={{ shrink: true }}
              value={searchType}
              onChange={(e) => searchTypeChanged(e.target.value)}
              items={searchTypes}
              label=""   // seems like it's a mandatory prop
              //className={"transactionEnquiry__radioButton_direction"}
              //actionType={"hasURRSearchAccess"}   // DO I DEFINE A PERMISSION FOR THIS RADIO BTN ??
            />
          </Box>
        </Stack>

        {transactionData !== null && transactionData !== undefined && transactionData.length > 0 ? (
          <>
            <Box className="manageUsers_table_visible">
              <ERPSGrid
                data={{
                  rows: transactionData,
                  columns: upcomingRecallsRejectsHeading(componentAccess
                    //fiNumberValue,
                    //roleId,
                    //user?.fiNumber
                  ),
                  count: urrResponse?.upcomingRecallsRejectsData?.count,
                }}
                onAnyPaginationAction={paginateURR}
                /*dataGridWidth={
                  !bmoWithWebPortal(fiNumberValue, roleId)
                    ? "dataGrid__box__container__transactionEnquiry_v1"
                    : "dataGrid__box__container__transactionEnquiry_v2"
                }*/
                ///initialLoading={initialLoading}
                pageNumber={pageNumber}
                setPage={setPage}
                rowPerPage={rowPerPage}
                tableLabelText={"_Page Title_"}
                //tableLabelText={t("erps.transactionEnquiry.page.title")}
                hideFooterPagination={false}
                pageName={"transaction-enquiry-urr"}
              />
            </Box>
            <Box className="manageUsers_card_visible">
              <CardList
                data={{
                  rows: transactionData,
                  columns: upcomingRecallsRejectsHeading(
                    //componentAccess,
                    //fiNumberValue,
                    //roleId,
                    //user?.fiNumber
                  ),
                }}
                //onAnyPaginationAction={paginateTransactionEnquiry}
                hideFooterPagination={false}
                pageName={"upcoming-recalls-rejects"}
                pageNumber={pageNumber}
                setPage={setPage}
                rowPerPage={rowPerPage}
              />
            </Box>
          </>
        )
        : ("")
        }
        {(transactionData === null || transactionData === undefined) && (
          <Box mt={1}>
            <Typography variant="body1">
              {" "}
              {/*t(
                "erps.availabledocuments.searchuser.notstarted",
                "Search not started"
              )*/}
              {"Search not started"}
            </Typography>
          </Box>
        )}
        {Array.isArray(transactionData) && transactionData?.length === 0 && (
          <Box mt={1}>
            <Typography variant="body1">
              {" "}
              {`${t("erps.common.message.noResultsFound")}`}
            </Typography>
          </Box>
        )}

      </Box>
    </div>
  );
};

export default withSidebar(UpcomingRecallsRejects);

function ts() {  return new Date().toISOString().substring(11, 23);  } // 2020-05-12T23:50:21.817Z
