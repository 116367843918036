import Keycloak from "keycloak-js";
import store, { persistor } from "./../store";
import loginActionTypes from "./../actions/types/login.action.type";
import { API_BASE_URL, roleConfigUrls } from "./helper";
import axios from "axios";
import fiConfig from "./../fiConfig.json";

const base_url = window.location.origin;
// Get curent FI as sometime not getting value in localstorage
let currentUrl = window.location.href;
const subDomain = currentUrl.split("/")[2].split(".")[0];
let fiNumber;
let type;
if (fiConfig[subDomain]) {
  ({ fiNumber, type } = fiConfig[subDomain]);
} else {
  fiNumber = 50;
}
let realm = "";
let clientId = "";
if (fiNumber == 26) {
  realm = "dyedurham_erps_pcb";
  clientId = "pcb_client_account";
} else if (fiNumber == 40) {
  realm = "dyedurham_erps_manulife";
  clientId = "manulife_client_account";
} else if (fiNumber == 16) {
  realm = "dyedurham_erps_hsbc";
  clientId = "hsbc_client_account";
} else if (fiNumber == 37) {
  realm = "dyedurham_erps_mcu";
  clientId = "mcu_client_account";
} else if (fiNumber == 88) {
  realm = "dyedurham_erps_tfx";
  clientId = "tfx_client_account";
} else if (fiNumber == 50) {
  realm = "dyedurham_erps_dnd";
  clientId = "dnd_client_account";
} else if (fiNumber == 61) {
  realm = "dyedurham_erps_tangerine";
  clientId = "tangerine_client_account";
} else if (fiNumber == 36) {
  realm = "dyedurham_erps_motus";
  clientId = "motus_client_account";
} else if (fiNumber == 99 && type == "classic") {
  realm = "dyedurham_erps_bmo_classic";
  clientId = "bmo_classic_client_account";
} else if (fiNumber == 99 && type == "webportal") {
  realm = "dyedurham_erps_bmo_webportal";
  clientId = "bmo_webportal_client_account";
} else if (fiNumber == 10 && type == "classic") {
  realm = "dyedurham_erps_cibc_classic";
  clientId = "cibc_classic_client_account";
} else if (fiNumber == 10 && type == "webportal") {
  realm = "dyedurham_erps_cibc_webportal";
  clientId = "cibc_webportal_client_account";
} else if (fiNumber == 2) {
  realm = "dyedurham_erps_bns";
  clientId = "bns_client_account";
}

const currentEnv = currentUrl.split("/")[2].split(".")[1];
let keycloakUrl = "";
if (currentEnv == "erpsqa") {
  keycloakUrl = "https://sso.erpsqa.ca/auth";
} else if (currentEnv == "erpsdev") {
  keycloakUrl = "https://sso.erpsdev.ca/auth";
} else if (currentEnv == "erpsstage02") {
  keycloakUrl = "https://sso.erpsstage02.ca/auth";
} else if (currentEnv == "erpsstage") {
  keycloakUrl = "https://sso.erpsstage.ca/auth";
} else if (currentEnv == "can-act-billpay") {
  keycloakUrl = "https://sso.can-act-billpay.com/auth";
} else {
  keycloakUrl = "https://sso-dev.dyedurham.dev/auth";
}

const _kc = new Keycloak({
  url: keycloakUrl,
  realm: realm,
  clientId: clientId,
  redirectUri: `${base_url}/auth-redirect`,
});

const { dispatch } = store;

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (props) => {
  _kc
    .init({
      onLoad: "check-sso",
      pkceMethod: "S256",
      redirectUri: `${base_url}/auth-redirect`,
      silentCheckSsoFallback: false,
      silentCheckSsoRedirectUri: `${location.origin}/silent-check-sso.html`,
    })
    .then((authenticated) => {
      if (props.setIsLoggedIn) {
        props?.setIsLoggedIn(true);
      }
      if (authenticated) {
        axios
          .post(
            API_BASE_URL() + "/users/authenticate",
            {
              fiNumber: getTokenParsed()?.attr_fi_number,
              userId: getTokenParsed()?.preferred_username,
              roleId: getTokenParsed()?.role_id,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + getToken(),
                "Login-UserId": getTokenParsed()?.preferred_username,
              },
            }
          )
          .then((res) => {
            // props.setLoginPending(false);
            res.token = getToken();
            dispatch(success(res));

            const data = JSON.parse(JSON.stringify(res.data));
            if (data) {
              const {
                role: { role_type },
              } = data;

              if (
                role_type === "MA" &&
                data.billerNonVolatileDto &&
                data.billerNonVolatileDto.agreement_accepted != "A"
              ) {
                props.props.history.push("/welcome-page");
              } else {
                if (data.statusCode !== "I") {
                  // if (data.changePasswordIndex == "N") {
                  if (data.emailAddress || data.emailAddress != "") {
                    const roleId = data?.role?.role_id;
                    let path;
                    if (roleId == 7) {
                      path =
                        data?.fiNumber == 10 || data?.fiNumber == 16
                          ? "/manage-billers"
                          : "/review-transactions";
                    } else {
                      path = roleConfigUrls[roleId] || "/dashboard";
                    }

                    props.props.history.push(path);
                  } else {
                    props.props.history.push("/account-settings");
                  }
                  // } else {
                  //   if (data.emailAddress || data.emailAddress != "") {
                  //     props.props.history.push("/update-password");
                  //   } else {
                  //     props.props.history.push("/account-settings");
                  //   }
                  // }
                }
              }
            }

            if (data.languageCode == "E") {
              i18next.changeLanguage("en");
            } else if (data.languageCode === "F") {
              i18next.changeLanguage("fr");
            }
          })
          .catch((error) => {
            // const errMessage = getExceptionMessage(error, props.t);
            // props.setLoginPending(false);
            // dispatch({ type: loginActionTypes.AUTHENTICATION_FAIL, error: "Login failure" });
          });

        // document.getElementById('loggedIn').innerText = getUsername() + ' is logged in' ;

        // const parseValue = getTokenParsed();

        // document.getElementById('token').innerText =  JSON.stringify(getTokenParsed());
      } else {
        // document.getElementById("loggedIn").innerText = "not logged";
      }
    });

  function success(data) {
    return { type: loginActionTypes.AUTHENTICATION_SUCCESS, data };
  }
};

const doLogin = _kc.login;

const doLogout = _kc.logout;

const getToken = () => _kc.token;

const getTokenParsed = () => _kc.tokenParsed;

const isLoggedIn = () => !!_kc.token;

const updateToken = (successCallback) =>
  _kc.updateToken(5).then(successCallback).catch(doLogin);

const getUsername = () => _kc.tokenParsed?.preferred_username;

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  getTokenParsed,
  updateToken,
  getUsername,
  hasRole,
  base_url,
};

export default UserService;
