import React, { useRef, useEffect, useState } from "react";
import { withSidebar } from "../hoc/withSidebar";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../actions";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { Box } from "@material-ui/core";
import CommonContainerBox from "../custom/containers/CommonContainerBox";
import ERPSBaseButton from "../custom/inputs/ERPSBaseButton";
import ERPSBaseDatePicker from "../custom/inputs/ERPSDatePicker";
import ERPSBaseRadioGroup from "../custom/inputs/ERPSBaseRadioGroup";
import ERPSBaseTextField from "../custom/inputs/ERPSBaseTextField";
import ERPSGrid from "../custom/containers/ERPSGrid";
import CardList from "../custom/containers/cardList";
import ErrorMessage from "../custom/containers/ErrorMessage";
import { recallRejectHistoryHeading } from "../../constants/RecallRejectHistoryConstants";
import { getComponentAccess, sanitizeQueryParams, formatDate } from "../../utils/helper";
import moment from "moment";
import { alphaNumericRegex, alphaNumericWithMarksRegex, nameRegex, nameWildCharRegex, transactionStatusMap}  from "../../constants/sharedConstants.js";


const RecallRejectHistory = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    history,
    location: { state },
  } = props;

  const [loading, setLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [rowPerPage, setRowPerPage] = useState(7); // 100
  const [highlightError, setHighlightError] = useState({});
  const [validationErrors, setValidationErrors] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const errorRef = useRef();
//  const alertRef = useRef(null);   // DO I NEED THIS ??

  useEffect(() => {
    if (errorRef.current)  errorRef.current.focus();
  }, [validationErrors.length, errorMessage]);

  const user = useSelector((state) => state.loginUserReducer.user);

  const componentAccess = state?.componentAccess
          || getComponentAccess( { function_type_code: "RRH", label_type_code: "TE" }, user );

  const transactionTypes = [
    { name: t("erps.transactionManagement.label.showCustomerRecalls"), value: "RT" },
    { name: t("erps.transactionManagement.label.showMerchantRejects"), value: "RJ" }
  ];
  const billerDetails = [
    {name: t("erps.common.label.billerID"), value: "bmoBillerId" },
    {name: t("erps.common.label.billerName"), value: "billerName" }
  ];

  // filter will only keep Txn Statuses for F, W, X.
  const transactionStatusList = transactionStatusMap.entries().filter(e => "FWX".indexOf(e[0]) > -1)
                                 .map(e => ({value: e[0], label: e[1]})).toArray();
  transactionStatusList.unshift({value: " ", label: "All"});

  const addDays = (date, daysToAdd) => {
    let result = date ? new Date(date) : new Date();
    result.setDate(date.getDate() + daysToAdd);
    return result;
  };

  const [dates] = useState(() => {
    let now = new Date();
    now = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    return {
      today: now,
      aMonthAgo: addDays(now, -31),
      minStartDateValue: addDays(now, -90),
      minEndDateValue: addDays(now, -89),
      maxDateValue: now,
      maxDateIntervalMs: 31*24*60*60*1000 + 60*60*1000,  // 31 days + 1hr in milliseconds (+1hr to account for an extra hour summer time switch)
    };
  });

  const initialSearchValues = {
    transactionType: transactionTypes[0].value,
    paymentFromDate: dates.aMonthAgo,
    paymentToDate: dates.today,
    transactionStatus: transactionStatusList[0].value,
    fiNumber: "0",
    searchBy: billerDetails[0].value,
    bmoBillerId: "",
    billerName: "",
    billerCustomerNumber: "",
    fiCustomerName: "",
    // sortBy: "ED",
  };

  const rrhResponse = useSelector( (state) => state.transactionEnquiryReducer );

  const [searchValues, setSearchValues] = useState(initialSearchValues);
  const [actualCriteria, setActualCriteria] = useState(() => {
    return { ...initialSearchValues, offset: 0, limit: rowPerPage};
  });
  
  const [transactionData, setTransactionData] = useState( state?.fromDetailPage && rrhResponse?.recallRejectHistoryData || null);
  const [financialInstitutionList, setFinancialInstitutionList] = useState([]);
  const [financialInstitutionMap, setFinancialInstitutionMap] = useState(null);

  const financialInstitutionResponse = useSelector( (state) => state.fiReducer?.financialInstitutionList );

  useEffect(() => {
    if (errorRef.current) {
        errorRef.current.focus();
    }
  }, [validationErrors.length, errorMessage]);

  useEffect(() => {
    dispatch(actions.fiActions.getFinancialInstitutionList(t));
    if (state?.searchedValues) {
        setSearchValues(state?.searchedValues);
    } else {  // reset search resutls previously saved in the reducer
      dispatch({
        type: "GET_RECALL_REJECT_HISTORY_SUCCESS",
        data: { data: null, recallRejectHistoryDetails: null },
      });
    }
  }, []);


  useEffect(() => {
    if (financialInstitutionResponse && financialInstitutionResponse.length) {
        const data = financialInstitutionResponse.map(
            ({ fi_name, fi_number }) => ({ value: fi_number, label: `${fi_number} - ${fi_name}` })
        );
        data.unshift({value: "0", label: "All FI's"});
        setFinancialInstitutionList(data);
        setFinancialInstitutionMap(new Map(financialInstitutionResponse.map( ({ fi_number, fi_name }) => [fi_number, fi_name])));
    }
  }, [financialInstitutionResponse]);

//   useEffect(() => {

  //   }, [searchValues]);

  useEffect(() => {
    if (rrhResponse) {
      const responseData = rrhResponse.recallRejectHistoryData?.data;
      if (responseData && responseData?.length) {
        for(let i=0; i < responseData.length; i++) {
          responseData[i].id = i + 1 + actualCriteria.offset;  // this direct update to responseData will be visible in the Details screen
        }
      }
      setTransactionData(responseData);        
    }
  }, [rrhResponse]);

  const paginate = (values) => {  // 'values' has fields startOffset and endOffset (e.g., for 1st Page: 0,25;  2nd Page: 25,50;  3rd: 50,75...)
    actualCriteria.offset = values.startOffset;
    if (actualCriteria.limit !== (values.endOffset - values.startOffset)) // if the user changed the number of rows per page
      actualCriteria.limit = values.endOffset - values.startOffset;

    // we need this when coming back from the details page ??
    //const pageNum = values.startOffset / (values.endOffset - values.startOffset);
    //setPageNumber(pageNum);

    loadResults(actualCriteria, " (paginate)");
  };

  const loadResults = (queryParams) => {
    if (errorMessage)
      setErrorMessage(null);
    setLoading(true);
    setLoadingMsg(null);

    dispatch(
      actions.transactionEnquiryActions.searchRecallRejectHistory(
        queryParams,
        { ...props, t, history, setLoading, setErrorMessage}
      )
    );
  };

  const handleReset = () => {
    setHighlightError({});
    setSearchValues(initialSearchValues);
    setValidationErrors([]);
    setPageNumber(0);
    setErrorMessage(null);

    dispatch({
      type: "GET_RECALL_REJECT_HISTORY_SUCCESS",
      data: { data: null, recallRejectHistoryDetails: null },
    });
  };

  const handleSearch = () => {
    const groomedValues = { ...sanitizeQueryParams({ ...searchValues }) };

    if (groomedValues.searchBy === "bmoBillerId")
      delete groomedValues["billerName"];
    else if (groomedValues.searchBy === "billerName")
      delete groomedValues["bmoBillerId"];

    const { errors, highlightError } = validateSearchValues(groomedValues);
    if (errors.length) {
        setValidationErrors(errors);
        setHighlightError(highlightError);
    } else {
      const {
          transactionType, searchBy, offset, limit,   // Excluding these fields from the mininum field count
          ...rest
      } = groomedValues;
      if (rest && typeof rest === "object" && Object.keys(rest)?.length < 3) {
        setValidationErrors([ t("erps.transactionEnquiry.page.errorMessage.minimumthreefields.required") ]);
      } else {
        if (groomedValues.paymentFromDate)
          groomedValues.paymentFromDate = formatDate(moment(groomedValues.paymentFromDate).format());

        if (groomedValues.paymentToDate)
          groomedValues.paymentToDate = formatDate(moment(groomedValues.paymentToDate).format());

        if (!groomedValues.transactionStatus)
          groomedValues.transactionStatus = transactionStatusList.slice(1).map(ts => ts.value).join(",");

        setValidationErrors([]);
        setHighlightError({});
        if (pageNumber)
            setPageNumber(0);
        if (actualCriteria.offset)
          actualCriteria.offset = 0;

        groomedValues.limit = actualCriteria.limit;
        groomedValues.offset = actualCriteria.offset;
        setActualCriteria(groomedValues);
        loadResults(groomedValues, " (search)");
      }
    }
  };

  const handleFieldChange = (fieldName, newValue) => {
    if (loading) {
      setLoadingMsg("The page is loading, please wait");
    } else {
      searchValues[fieldName] = newValue;
      setSearchValues( {...searchValues} );  // to trigger screen rendering with the new value
    }
  };

  // THIS validate FUNCTION WAS COPIED FROM RejectTransactions.js
  // IT MUST BE MOVED INTO A COMMON PLACE FOR RE-USE,  BUT WHERE ???
  const validateSearchValues = (req) => {
    let errors = [];
    let highlightError = {};
    const date1 = new Date(req.paymentFromDate);
    const date2 = new Date(req.paymentToDate);

    if (!req.paymentFromDate) {
        errors.push(t("erps.transactionEnquiry.page.errorMessage.paymentFromDate.required"));
        highlightError = { ...highlightError, paymentFromDate: true };
    } else {
      if (dates.minStartDateValue && date1.getTime() < dates.minStartDateValue.getTime()) {
        errors.push(t("erps.common.datepicker.startDatenotbeforemaximaldate") + " " + moment(dates.minStartDateValue).format("YYYY-MM-DD"));
        highlightError = {...highlightError, paymentFromDate: true };
      }
      if (dates.maxDateValue && date1.getTime() > dates.maxDateValue.getTime()) {
        errors.push(t("erps.common.datepicker.startdatenotaftermaximaldate") + " " + moment(dates.maxDateValue).format("YYYY-MM-DD"));
        highlightError = {...highlightError, paymentFromDate: true };
      }
    }
    if (!req.paymentToDate) {
        errors.push(t("erps.transactionEnquiry.page.errorMessage.paymentToDate.required"));
        highlightError = { ...highlightError, paymentToDate: true };
    } else {
      if (dates.minEndDateValue && date2.getTime() < dates.minEndDateValue.getTime()) {
        errors.push(t("erps.common.datepicker.endDatenotbeforemaximaldate") + " " + moment(dates.minEndDateValue).format("YYYY-MM-DD"));
        highlightError = {...highlightError, paymentToDate: true };
      } else if (dates.maxDateValue && date2.getTime() > dates.maxDateValue.getTime()) {
        errors.push(t("erps.common.datepicker.endDatenotaftermaximaldate") + " " + moment(dates.maxDateValue).format("YYYY-MM-DD"));
        highlightError = { ...highlightError, paymentToDate: true };
      }
    }
    if (date2.getTime() < date1.getTime()) {
      errors.push(t("erps.transactionEnquiry.page.errorMessage.fromDate"));
      highlightError = {...highlightError, paymentFromDate: true, paymentToDate: true };
    } else if (date2.getTime() - date1.getTime() > dates.maxDateIntervalMs) {
      errors.push(t("erps.transactionEnquiry.page.errorMessage.dateRangeForOneMonth"));
      highlightError = {...highlightError, paymentFromDate: true, paymentToDate: true };
    }

    if (req.searchBy === "bmoBillerId" && !req.bmoBillerId) {
        errors.push(t("erps.transactionEnquiry.page.errorMessage.billerId.required"));
        highlightError = { ...highlightError, bmoBillerId: true };
    }
    if (req.searchBy === "billerName" && !req.billerName) {
        errors.push(t("erps.transactionEnquiry.page.errorMessage.billerName.required"));
        highlightError = { ...highlightError, billerName: true };
    }
    if (req.bmoBillerId && !alphaNumericRegex.test(req.bmoBillerId)) {
        errors.push(t("erps.transactionEnquiry.page.errorMessage.billerId.invalid"));
        highlightError = { ...highlightError, bmoBillerId: true };
    }

    if (req.billerName && !nameRegex.test(req.billerName)) {
        errors.push(t("erps.transactionEnquiry.page.errorMessage.billerName.invalid"));
        highlightError = { ...highlightError, billerName: true };
    } else if (req.billerName &&
        ((req.billerName.includes("%") && req.billerName.length < 4) || !nameWildCharRegex.test(req.billerName) ||
            (req.billerName.includes("%") && !isCharOccurringOnce(req.billerName, "%"))) ) {
        errors.push(t("erps.transactionEnquiry.page.errorMessage.billerName.minChar"));
        highlightError = { ...highlightError, billerName: true };
    }

    if (req.billerCustomerNumber && !alphaNumericRegex.test(req.billerCustomerNumber)) {
      errors.push(t("erps.transactionEnquiry.page.errorMessage.customerAccountNumber.invalid"));
      highlightError = { ...highlightError, billerCustomerNumber: true };
    }
    if (req.fiCustomerName && !alphaNumericWithMarksRegex.test(req.fiCustomerName)) {
      errors.push(t("erps.transactionEnquiry.page.errorMessage.customerName.invalid"));
      highlightError = { ...highlightError, fiCustomerName: true };
    }

    return { errors, highlightError };
  };

  function isCharOccurringOnce(str, char) {
    return  str?.split(char)?.length <= 3;
  }

  return (
    <div role="main">
      <Box sx={{ ml: 3 }}>
        { (!validationErrors.length && !errorMessage) ? ("") : (
          <Box role="alert" className="manageUsers__errorMsg__container" sx={{ mt: 2 }}>
            <ErrorMessage errorRef={errorRef}
                          error={validationErrors?.length ? validationErrors : errorMessage} />
          </Box>
        )}

        <Box className="manageUsers__page__title">
          {/* For Title update */}
          <CommonContainerBox title={t("erps.recallsrejectshistory.page.title")} />
          <Typography variant="h1">{t("erps.recallsrejectshistory.page.title")}</Typography>
        </Box>
        <Box sx={{ mt: 4 }}>
            <Typography variant="h2">{t("erps.transactionEnquiry.page.description")}</Typography>
        </Box>
        <Box sx={{ mt: 0 }}>
            <Typography variant="body1">{t("erps.transactionEnquiry.page.wildcardDescription")}</Typography>
        </Box>

        {/* <Box sx={{ mt: 3 }}>
            <Typography variant="h3">{t("erps.transactionEnquiry.page.transactionDetails.page.subheading")}</Typography>
        </Box> */}
        <Stack sx={{ mt: -1 }} direction="row">
          <Box>
            <ERPSBaseRadioGroup id="transactionType"
              value={searchValues?.transactionType}
              name="transactionType"
              //InputLabelProps={{ shrink: true }}
              onChange={e => handleFieldChange("transactionType", e.target.value)}
              items={transactionTypes}
              label=""   // seems like it's a mandatory prop
              //actionType={"hasURRSearchAccess"}   // DO I DEFINE A PERMISSION FOR THIS RADIO BTN ??
              //className={"transactionEnquiry__radioButton_direction"}  // this removes the top margin
              marginTop={true}   // this eliminates the default top margin which is quite large
            />
          </Box>
        </Stack>

        <Stack sx={{ mt: -2 }} spacing={2} direction="row">
          <ERPSBaseDatePicker id="paymentFromDate"
              value={searchValues?.paymentFromDate}
              label={t("erps.common.label.startDate", "Start date")}
              onChange={e => handleFieldChange("paymentFromDate", e)}
              className="app_datepicker_biller_transactionEnquiry"
              hasMargin="true"
              datePickerStyle={"quick-reg-datepicker-v1"}
              maxDate={dates?.maxDateValue}
              minDate={dates?.minStartDateValue}
              error={highlightError?.paymentFromDate}
              required={true} />
          <ERPSBaseDatePicker id="paymentToDate"
              value={searchValues?.paymentToDate}
              label={t("erps.common.label.endDate", "End date")}
              onChange={e => handleFieldChange("paymentToDate", e)}
              className="app_datepicker_biller_transactionEnquiry"
              hasMargin="true"
              datePickerStyle={"quick-reg-datepicker-v1"}
              maxDate={dates?.maxDateValue}
              minDate={dates?.minEndDateValue}
              error={highlightError?.paymentToDate}
              required={true} />
          <ERPSBaseTextField id="transactionStatus"
              value={searchValues?.transactionStatus}
              type="text"
              select={true}
              label={t("erps.common.label.status", "Status")}
              className="two_column__textField"
              onChange={e => handleFieldChange("transactionStatus", e.target.value)}
              size="small"
              required="true"
              items={transactionStatusList}
              SelectProps={{ native: true }}
              InputLabelProps={{ shrink: searchValues?.transactionStatus ? true : false }} />

          {searchValues?.transactionType === "RJ" && (
            <ERPSBaseTextField id="fiNumber"
                value={searchValues?.fiNumber}
                type="text"
                select={true}
                label={t("erps.common.label.financialInstitution", "xFinancial Institution")}
                className="three_column__textField"
                onChange={e => handleFieldChange("fiNumber", e.target.value)}
                size="small"
                required="true"
                items={financialInstitutionList}
                SelectProps={{ native: true }}
                InputLabelProps={{ shrink: searchValues?.fiNumber ? true : false }} />
          )}
        </Stack>

        <Box sx={{ mt: 3 }}>
          <Typography variant="h3">
            {t("erps.transactionEnquiry.componentToPrint.page.subheading.billerDetails")}
          </Typography>
        </Box>
        <Stack sx={{ mt: 0 }} spacing={2} direction="row">
            <Box sx={{ mt: 0 }}>
              <ERPSBaseRadioGroup id="searchBy"
                  value={searchValues?.searchBy}
                  name="searchBy"
                  InputLabelProps={{ shrink: true }}
                  label={t("erps.common.label.searchBy")}
                  onChange={e => handleFieldChange("searchBy", e.target.value)}
                  items={billerDetails}
                  error={highlightError?.searchBy}
                  labelPlacement="start"
                  marginTop={true}  // What is this for??
                  className={"transactionEnquiry__radioButton_direction"}
              />
            </Box>
            <Box>
              {searchValues?.searchBy === "bmoBillerId" && (
                  <ERPSBaseTextField id="bmoBillerId"
                      value={searchValues?.bmoBillerId}
                      type="text"
                      label={t("erps.common.label.billerID")}
                      className="transactionEnquiry__textField__width"
                      hasMargin="true"
                      size="small"
                      required={"true"}
                      onChange={(e) => handleFieldChange("bmoBillerId", e.target.value)}
                      error={highlightError?.bmoBillerId}
                      inputProps={{ maxLength: 9 }}
                  />
              )}
              {searchValues?.searchBy === "billerName" && (
                  <ERPSBaseTextField id="billerName"
                      value={searchValues?.billerName}
                      type="text"
                      label={t("erps.common.label.billerName")}
                      className="transactionEnquiry__textField__width"
                      hasMargin="true"
                      required={"true"}
                      size="small"
                      onChange={(e) => handleFieldChange("billerName", e.target.value)}
                      error={highlightError?.billerName}
                      inputProps={{ maxLength: 45 }}
                  />
              )}
            </Box>
        </Stack>

        <Box sx={{ mt: 3 }}>
          <Typography variant="h3">
              {t("erps.common.label.customerDetails")}
          </Typography>
        </Box>
        <Stack sx={{ mt: 2 }} spacing={2} direction="row">
          <ERPSBaseTextField id="billerCustomerNumber"
              value={searchValues?.billerCustomerNumber}
              type="text"
              label={t("erps.common.label.customerAccountNumber")}
              className="transactionEnquiry__textField__width"
              hasMargin="true"
              size="small"
              onChange={(e) => handleFieldChange("billerCustomerNumber", e.target.value) }
              error={highlightError?.billerCustomerNumber}
              inputProps={{ maxLength: 45 }} />
          <ERPSBaseTextField id="fiCustomerName"
              value={searchValues?.fiCustomerName}
              type="text"
              label={t("erps.common.label.customerName")}
              className="transactionEnquiry__textField__width"
              hasMargin="true"
              size="small"
              onChange={(e) => handleFieldChange("fiCustomerName", e.target.value)}
              error={highlightError?.fiCustomerName}
              inputProps={{ maxLength: 30 }} />
        </Stack>

        <Stack sx={{ mt: 4 }} spacing={2} direction="row">
          <ERPSBaseButton
              onClick={handleReset}
              variant="secondary"
              className="button_with_zeromargin"
              label={t("erps.common.button.reset")}
              //componentAccess={componentAccess}
              actionType={"hasTransactionResetBtnAccess"}
          />
          <ERPSBaseButton
              onClick={handleSearch}
              variant="primary"
              className="button_with_zeromargin"
              label={t("erps.common.button.search")}
              loading={loading}
          />
        </Stack>
        <Box sx={{ mt: 3 }}>
            <Typography variant="h2"> {t("erps.manageusers.searchuser.searchresults")} </Typography>
        </Box>

        {(transactionData === null || transactionData === undefined || !Array.isArray(transactionData)) ? (
          <Box sx={{ mt: 1 }}>
            <Typography variant="body1">
              {t("erps.common.message.searchNotStarted", "Search not started")}
            </Typography>
          </Box>
        ) : transactionData.length == 0 ? (
            <Box sx={{ mt: 1 }}>
            <Typography variant="body1">
              {`${t("erps.common.message.noResultsFound")}`}
            </Typography>
          </Box>
        ) : (  // we're here if transactionData.length > 0
            <>
            <Box className="manageUsers_table_visible">
              <ERPSGrid
                data={{
                  rows: transactionData,
                  columns: recallRejectHistoryHeading(componentAccess, financialInstitutionMap),
                  count: rrhResponse?.recallRejectHistoryData?.count || 0,
                }}
                //dataGridWidth="dataGrid__box__container__ ... a special CSS class just for this table?  .... "
                tableWidth={recallRejectHistoryHeading().map(c => c.width).reduce((a,b) => a+b) + 12}  // I don't why we need to add exactly 12
                onAnyPaginationAction={paginate}
                initialLoading={loading}
                pageNumber={pageNumber}
                setPage={setPageNumber}
                rowPerPage={rowPerPage}
                tableLabelText={"_Page Title_"}
                //tableLabelText={t("erps.transactionEnquiry.page.title")}
                hideFooterPagination={false}
                pageName={"transaction-enquiry-rrh"}
              />
            </Box>
            <Box className="manageUsers_card_visible">
              <CardList
                data={{
                  rows: transactionData,
                  columns: recallRejectHistoryHeading(componentAccess, financialInstitutionMap),
                  count: rrhResponse?.recallRejectHistoryData?.count || 0,
                }}
                onAnyPaginationAction={paginate}
                hideFooterPagination={false}
                pageName={"recall-reject-history"}
                pageNumber={pageNumber}
                setPage={setPageNumber}
                rowPerPage={rowPerPage}
              />
            </Box>
          </>
        )}

      </Box>
    </div>
  );
};

export default withSidebar(RecallRejectHistory);
