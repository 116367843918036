import React from "react";
////////////////////////////
// 3rd Party Dependencies //
////////////////////////////
import { Box, Grid } from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { PropTypes } from "prop-types";
import MomentUtils from "@date-io/moment";
import "date-fns";
import { useTranslation } from "react-i18next";
import moment from "moment";

export default function ERPSBaseDatePicker(props) {
  const {
    id,
    label,
    onChange: handleDateChange,
    value: selectedDate,
    className,
    required,
    datePickerStyle,
    maxDate,
    minDate,
    hasRequired = true,
    hasMinMaxNotCheck = false,
    isDisabled,
    format = "YYYY-MM-DD",
    views = ["year", "month", "date"],
    animateYearScrolling = true,
    error = false,
    shouldDisableDate = false,
  } = props;
  const todayDate = new Date();
  const minDateVal = new Date(
    todayDate.getFullYear(),
    todayDate.getMonth(),
    todayDate.getDate() - 90
  );
  const maxDateVal = new Date(
    todayDate.getFullYear(),
    todayDate.getMonth(),
    todayDate.getDate() + 90
  );
  const { t } = useTranslation();

  const compareArrays = (a, b) => {
    return a.toString() === b.toString();
  };

  const getNotBlankMessage = () => {
    if (compareArrays(views, ["year"])) {
      return t("erps.common.datepicker.periodNotBlank", { period: "Year" });
    } else if (compareArrays(views, ["year", "month"])) {
      return t("erps.common.datepicker.periodNotBlank", { period: "Month" });
    } else {
      return t("erps.common.datepicker.cantblank");
    }
  };

  const getInvalidFormatMessage = () => {
    if (compareArrays(views, ["year"])) {
      return t("erps.common.datepicker.periodInvalidformat", {
        period: "year",
      });
    } else if (compareArrays(views, ["year", "month"])) {
      return t("erps.common.datepicker.periodInvalidformat", {
        period: "month",
      });
    } else {
      return t("erps.common.datepicker.invalidformat");
    }
  };
  const checkMinMaxDateValue = (min, max, curr) => {
    if (curr) {
      const val = new Date(curr);
      const hasCheck = val < min || val > max;
      return hasCheck;
    }
    return false;
  };

  const checkValidOrRequiredDates = () => {
    if (selectedDate === "Invalid date") {
      return true;
    }
    return false;
  };
  const maxValue = maxDate || maxDateVal;
  const minValue = minDate || minDateVal;
  const maxValueFormatted = moment(maxValue).format(format);
  const minValueFormatted = moment(minValue).format(format);

  return (
    <div className={className}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <KeyboardDatePicker
          className={`quick-reg-datepicker ${datePickerStyle}`}
          disableToolbar
          role={"form"}
          allowKeyboardControl={true}
          animateYearScrolling={animateYearScrolling}
          autoOk={true}
          required={required}
          invalidDateMessage={
            hasRequired
              ? !selectedDate
                ? getNotBlankMessage()
                : getInvalidFormatMessage()
              : ""
          }
          leftArrowButtonProps={{
            "aria-label": t("erps.common.datepicker.selectpreviousmonth"),
          }}
          maxDate={maxValue}
          minDate={minValue}
          maxDateMessage={hasMinMaxNotCheck ? "" : t("erps.common.datepicker.maximaldate") + " " + maxValueFormatted}
          minDateMessage={hasMinMaxNotCheck ? "" : t("erps.common.datepicker.minimaldate") + " " + minValueFormatted}
          rightArrowButtonProps={{
            "aria-label": t("erps.common.datepicker.selectnextmonth"),
          }}
          inputVariant="outlined"
          disabled={isDisabled}
          variant="inline"
          format={format}
          margin="normal"
          id={id || `timeout-duration`}
          label={label}
          value={selectedDate}
          onChange={(date) => {
            if (date === null) {
              handleDateChange("");
            } else {
              handleDateChange(date?.format());
            }
          }}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          views={views}
          error={
            checkMinMaxDateValue(minValue, maxValue, selectedDate) ||
            checkValidOrRequiredDates() ||
            error
          }
          shouldDisableDate={shouldDisableDate}
          // okLabel="ok"
          // cancelLabel="cancel"
        />
      </MuiPickersUtilsProvider>
    </div>
  );
}
ERPSBaseDatePicker.propTypes = {
  label: PropTypes.string.isRequired,
  // size: PropTypes.string.isRequired,
  // onChange: PropTypes.func.isRequired,
  // value: PropTypes.string.isRequired,
};
