import React, { useRef, useEffect, useState } from "react";
import { withSidebar } from "../hoc/withSidebar";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { Box } from "@material-ui/core";
import ERPSBaseButton from "../custom/inputs/ERPSBaseButton";
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogHeader from "../custom/inputs/ERPSBaseModelDialog";
import actions from "../../actions";
import moment from "moment";
import ConfirmationMessage from "../custom/containers/ConfirmationMesssage";
import ErrorMessage from "../custom/containers/ErrorMessage";
import DialogContent from "@mui/material/DialogContent";
import ERPSBaseTextField from "../custom/inputs/ERPSBaseTextField";
import ERPSTransactionDetails from "../custom/ERPSTransactionDetails";

const RecallPaymentCustomerDetails = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {
        location: { state },
    } = props;

    const user = useSelector((state) => state.loginUserReducer.user);

    const selectedFiNumber = useSelector((state) => state.fiReducer?.selectedFi);

    const rpcData = useSelector(
        (state) => state.recallPaymentCustomerReducer?.rpcData?.data
    );

    const successMessage = useSelector(
        (state) => state.transactionEnquiryReducer?.recallTransactionData
    );

    const errorMessage = useSelector(
        (state) => state.transactionEnquiryReducer?.recallTransactionError
    );

    const fi_number = selectedFiNumber ? selectedFiNumber : user?.fiNumber;

    const errorRef = useRef();
    const successRef = useRef();


    let data = rpcData
    ? rpcData.find(({ payment_id }) => payment_id === state.id)
    : {};

    const [showDialog, setShowDialog] = useState(false);
    const [recallLoading, setRecallLoading] = useState(false);
    const [recallReason, setRecallReason] = useState('');
    const [isRecalled, setIsRecalled] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);


    useEffect(() => {
        if (successRef.current) {
            successRef.current.focus();
        }
    }, [successMessage && showSuccessMessage]);

    useEffect(() => {
        if (errorRef.current) {
            errorRef.current.focus();
        }
    }, [errorMessage?.length]);

    useEffect(() => {
        dispatch({ type: "UPDATE_TRANSACTION_FOR_RECALL_SUCCESS", data: null })
    }, []);

    
    const formatDateTime = (d) => {
        if (d) {
            return moment(d).format("YYYY-MM-DD hh:mm:ss:SS");
        } else {
            return "";
        }
    };

    const handleChange = (event) => {
        setRecallReason(event.target.value);
    };

    const handleRecall = async  () => {
        const setLoading = setRecallLoading;
        setIsRecalled(true);
        setLoading(true);
        if (showSuccessMessage) {
            setShowSuccessMessage(false);
        }
        let reqBodyForRecall = {};
        if (data) {
            const {
                id,
                payment_date,
            } = data;
            const [date, time] = formatDateTime(new Date())?.split(" ");
            reqBodyForRecall = {
                correlation_id: id,
                fi_number,
                payment_date,
                recall_reject_reason: recallReason,
                recalled_rejected_by: user?.userId,
                language_code: user?.languageCode
            };
        }
        dispatch(
            actions.transactionEnquiryActions.recallTransaction(
                {  reqBodyForRecall },
                {
                    setShowSuccessMessage,
                    setLoading,
                    t,
                }
            )
        );
    };


    return(
        <div role="main">
            {successMessage && showSuccessMessage && (
                <ConfirmationMessage
                    message={successMessage}
                    setShowMessage={setShowSuccessMessage}
                    inputRef={successRef}
                />
            )}
            {errorMessage ? (
                <Box className="manageUsers__errorMsg__container" role="alert" mt={2}>
                    {errorMessage ? (
                        <ErrorMessage error={errorMessage} errorRef={errorRef} />
                    ) : (
                        ""
                    )}
                </Box>
            ) : (
                ""
            )}
            <Box st={{ ml: 3}}>
                <Breadcrumb {...props} fromDetailPage={true}/>
            </Box>

            <ERPSTransactionDetails data={data} />

            <Box id={"screenshot-element"} sx={{ ml: 3 }}>
                {successMessage  && (
                <Box sx={{ mt: 3, display: "flex" }}>
                    {" "}
                    <Typography className="transaction__enquiry__value">
                        {t("erps.transactionEnquiry.label.recall.reason")}
                        <Typography variant="body2">
                            {recallReason}
                        </Typography>
                    </Typography>
                    <Typography className="transaction__enquiry__value left_space">
                        {t(
                            "erps.transactionEnquiry.label.recalled.by"
                        )}
                        <Typography variant="body2">{user?.userId}</Typography>
                    </Typography>
                </Box>
                )}

                {!successMessage && (
                <Box>
                <ERPSBaseTextField
                    id="recallReason"
                    type="text"
                    label={t("erps.transactionEnquiry.label.recall.reason")}
                    className="transactionEnquiry__textField__width"
                    size="small"
                    onChange={handleChange}
                    value={recallReason}
                    inputProps={{ maxLength: 80 }}
                />
                </Box>
                    )}
            </Box>
            <Stack direction="row" spacing={2} ml={3}>
                <ERPSBaseButton
                    variant="secondary"
                    className="button_with_margin48"
                    label={t("erps.newuser.button.back", "Back")}
                    onClick={() =>
                        props.history.push("/recall-payments-customers", {
                            fromDetailPage: true,
                            isRecalled: isRecalled
                        })
                    }
                />
                {!successMessage ?(
                <ERPSBaseButton
                    variant="primary"
                    className="button_with_margin48"
                    label={t(
                                "erps.recallpaymentcustomers.page.label.confirm.recall"
                    )}
                    onClick={() => setShowDialog(true)}
                    loading={recallLoading}
                    isDisabled={isRecalled}
                />):(
                    //Logic needs to be added for print button
                    <ERPSBaseButton
                        variant="primary"
                        className="button_with_margin48"
                        label={t(
                            "erps.transactionEnquiry.page.transactionDetails.button.print"
                        )}
                        isDisabled={true}
                    />
                )}

            </Stack>
            <Dialog
                open={showDialog}
                onClick={() => {
                    setShowDialog(false);
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
            >
                <DialogHeader
                    id="alert-dialog-title"
                    onClose={() => {
                        setShowDialog(false);
                    }}
                    className={"box-dialog-header"}
                >
                </DialogHeader>
                <DialogContent
                    id="alert-dialog-description"
                    className={"cancel-dialog-content-size"}
                >
                    {t("erps.recallpaymentcustomers.page.transactionDetails.dialog.content")}
                </DialogContent>
                <DialogActions className={"box-dialog-buttons"}>
                    <ERPSBaseButton
                        variant="secondary"
                        className="button_with_zeromargin"
                        onClick={() => {
                            setShowDialog(false);
                        }}
                        label={t("erps.relatedbiller.label.cancel")}
                    />
                    <ERPSBaseButton
                        variant="primary"
                        className="button_with_zeromargin"
                        onClick={() => handleRecall()}
                        label={"Confirm"}
                    />
                </DialogActions>
            </Dialog>
        </div>

    );

};

export default withSidebar(RecallPaymentCustomerDetails);