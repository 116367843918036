import recallPaymentCustomerTypes from "../actions/types/recallPaymentCustomer.action.type";

export const recallPaymentCustomerInitialState = {
  error: null,
  rpcData: null,
  rpcDetails: null,
  count: 0,
};

export default function recallPaymentCustomerReducer(
  state = recallPaymentCustomerInitialState,
  action
) {
  switch (action.type) {
    case recallPaymentCustomerTypes.GET_RPC_SUCCESS:
      return {
        rpcData: action.data.data,
        error: null,
        rpcDetails: action.data.rpcDetails,
        count: action.data.count,
      };
    case recallPaymentCustomerTypes.GET_RPC_ERROR:
        return { 
          rpcData: null, 
          count: 0, 
          error: action.error 
        };
    default:
      return state;
  }
}
