import React from "react";
import { Box, Icon } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import warningIcon from "../../../assets/images/warning-icon.png";
import { useTranslation } from "react-i18next";

const ErrorMessage = (props) => {
  const { t } = useTranslation();
  const { error, errorRef } = props;
  const errorClass =
    Array.isArray(error) && error.length
      ? { marginTop: "-12px" }
      : { marginTop: "8px", marginLeft: "8px" };

  return (
    <Box tabIndex={0} ref={errorRef} style={{ display: "flex" }}>
      <Icon className="manageUsers__error__icon" aria-hidden={false}>
        <img src={warningIcon} alt="Error notification" />
      </Icon>

      <div style={{ ...errorClass }}>
        {Array.isArray(error) && error.length ? (
          <>
            <ul>
              {" "}
              <li
                style={{
                  color: "#C12334",
                  listStyle: "none",
                  marginLeft: "-20px",
                  marginTop: "20px",
                }}
              >
                {t("erps.common.error.title")}
              </li>
              {error.map((err, i) => (
                <li style={{ color: "#C12334" }} key={i}> {err} </li>
              ))}
            </ul>
          </>
        ) : (
          <Typography variant="inherit" style={{ color: "#C12334" }}>
            {error}
          </Typography>
        )}
      </div>
    </Box>
  );
};

export default ErrorMessage;
