import ERPSBaseLink from "../components/custom/inputs/ERPSBaseLink";
import { t } from "i18next";
import { requiredFormatDate, formatAmount } from "../utils/helper";

export const rejectTransactionsHeading = (
    roleId,
    loggedInUserFi
) => {
    return[
            {
                field: "payment_id",
                headerName: t("erps.transactionEnquiry.label.serialNo"),
                width: 70,
                sortable: false,
            },
            {
                field: "transaction_amount",
                headerName: t("erps.transactionEnquiry.page.paymentamout"),
                width: 140,
                sortable: false,
                renderCell: (params) => {
                    if (params.value) {
                        return formatAmount(params.value);
                    }
                },
            },
            {
                field: "effective_date_display_format",
                headerName: t("erps.transactionEnquiry.page.effectivedate"),
                width: 130,
                sortable: false,
                renderCell: (params) => {
                    return requiredFormatDate(params.value);
                },
            },
            {
                field: "verification_number",
                headerName: t("erps.transactionEnquiry.page.confirmationnumber"),
                width: 160,
                sortable: false,
            },
            {
                field:"biller_id",
                headerName: t("erps.transactionEnquiry.page.billerid"),
                width: 100,
                sortable: false,
                renderCell: (params) => {
                    return params?.row?.display_biller_id;
                },
            },
        {
            field:"fi_short_name",
            headerName: t("erps.rejectpaymentmerchants.page.label.fi"),
            width: 100,
            sortable: false,
            renderCell: (params) => {
                return params?.row?.fi_short_name;
            },
        },
            {
                field: "biller_customer_number",
                headerName: t("erps.transactionEnquiry.page.accountnumber"),
                width: 240,
                sortable: false,
            },

        {
            field: "fi_customer_name",
            headerName: t(
                "erps.transactionEnquiry.page.label.customerFiName"
            ),
            width: 180,
            sortable: false,
            valueParser: (value) => {
                return value;
            },
            renderCell: (params) => {
                return params.value;
            },
        },
        {
            field: "reject",
            headerName: t("erps.rejectpaymentmerchants.page.label.reject"),
            width: 70,
            sortable: false,
            renderCell: (params) => (
                <ERPSBaseLink
                    to={{
                        pathname: `/reject-payments-merchants/transaction-details`,
                        state: { id: params.row?.payment_id},
                    }}
                    className="link__blue"
                    label={t("erps.rejectpaymentmerchants.page.label.reject")}
                />
            ),
        },
        ];
};



