import axios from "axios";
import store from "../store/index";
import { getExceptionMessage, API_BASE_URL    , listProps } from "../utils/helper";

export const upcomingRecallsRejectsService = {
  searchUpcomingRejectsRecalls,
};

function searchUpcomingRejectsRecalls(transactionData, props) {
  return (dispatch) => {
    const user = store.getState()?.loginUserReducer.user;
    const fiDetail = store.getState()?.fiReducer;
    const token = store.getState()?.loginUserReducer.token;
    
    const { searchType, startOffset, endOffset, limit, ...rest } = transactionData; // WHY DO WE NEED THIS??

    axios
      .post(
        `${API_BASE_URL()}/searchUpcomingRejectsRecalls`,
        { transactionStatus: transactionData.transactionStatus,
          selectedFiNumber: fiDetail?.selectedFi,
          offset: transactionData.startOffset,
          limit: transactionData.limit,
          userId: user.userId,
          languageCode: user.languageCode,
          roleType: user?.role?.role_type,
          requestId: null
        },
        { headers: { "Content-Type": "application/json", Authorization: "Bearer " + token, } }
      )
      .then((res) => {
        if (props?.setLoading)
          props?.setLoading(false);
        if (props?.setErrorMessage)
          props?.setErrorMessage(null);
        // if (props?.setBtnLoading)
        //   props?.setBtnLoading(false);
        const response = {
          data: res.data,
          upcomingRecallsRejectsDetails: {
            //searchType,  /// WHY DO WE NEED THIS??  Commented out Sep 25
            ...transactionData,
          },
        };
        dispatch(success(response));
      })
      .catch((error) => {
        console.log("URR.service - .catch()   error: " + error);
        if (props?.setLoading)
          props?.setLoading(false);
        // if (props?.setBtnLoading)
        //   props?.setBtnLoading(false);
        let errorMessage = getExceptionMessage(error, props?.t);
        if (props?.setErrorMessage) {
          props?.setErrorMessage(errorMessage);
        }
        dispatch(failure(errorMessage));
      });
    
    function success(data) {
      return {
        type: "GET_URR_SUCCESS",
        data,
      };
    }

    function failure(error) {
      return {
        type: "GET_URR_ERROR",
        error,
      };
    }

  };
}