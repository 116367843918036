import axios from "axios";
import transactionEnquiryTypes from "../actions/types/transactionEnquiry.action.type";
import store from "../store/index";
import { getExceptionMessage, API_BASE_URL, formatDate } from "../utils/helper";
import moment from "moment";
import axiosRetry from 'axios-retry';

export const transactionEnquiryService = {
  getTransactionEnquiry,
  udpateRecallAndRetrace,
  getTransactionsForReject,
  rejectTransaction,
  cancelTransaction,
  recallTransaction,
  searchRecallRejectHistory
};

function getTransactionEnquiry(transactionData, props) {
  return (dispatch) => {
    const user = store.getState()?.loginUserReducer.user;
    const fiDetail = store.getState()?.fiReducer;
    const { startOffset, endOffset, paymentFromDate, paymentToDate, ...rest } =
      transactionData;
    const updatedPaymentFromDate = moment(paymentFromDate).format();
    const updatedPaymentToDate = moment(paymentToDate).format();
    const token = store.getState()?.loginUserReducer.token;

    axiosRetry(axios, {
      retries: 5,
      retryDelay: (retryCount) => {
        if (retryCount < 5) {
          if (props?.setErrorMessage && props?.t) {
            props?.setErrorMessage(
              props?.t("erps.transactionEnquiry.page.pending")
            );
          }
        }

        return retryCount * (retryCount < 2 ? 20000 : 30000); // time interval between retries
      },
      retryCondition: (error) => {
        return error.response.status === 504;
      },
      onRetry: (retryCount, error, requestConfig) => {
        const requestId = error?.response?.data?.message?.split("::")[0]?.trim();
        requestConfig.data = requestConfig.data?.replace('"requestId":null', `"requestId":"${requestId}"`);
      }
    });

    axios
      .post(
        `${API_BASE_URL()}/searchTransaction`,
        {
          ...rest,
          paymentFromDate: formatDate(updatedPaymentFromDate),
          paymentToDate: formatDate(updatedPaymentToDate),
          fiNumber: props?.fiNumberValue,
          selectedFiNumber: fiDetail?.selectedFi,
          fiCompanyNumber: transactionData?.subscribeEntityId
            ? transactionData.subscribeEntityId
            : user.fiCompanyNumber,
          offset: startOffset,
          limit: 100,
          userId: user.userId,
          languageCode: user.languageCode,
          roleType: user?.role?.role_type,
          requestId: null,
          requestFrom:"reviewTransactions"
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        if (props?.setLoading) {
          props?.setLoading(false);
        }
        if (props?.setErrorMessage) {
          props?.setErrorMessage(null);
        }
        if (props?.setBtnLoading) {
          props?.setBtnLoading(false);
        }
        const response = {
          data: res.data,
          transactionEnquiryDetails: {
            ...transactionData,
            paymentFromDate,
            paymentToDate,
          },
        };
        dispatch(success(response));
      })
      .catch((error) => {
        if (props?.setLoading) {
          props?.setLoading(false);
        }
        if (props?.setBtnLoading) {
          props?.setBtnLoading(false);
        }
        let errorMessage = getExceptionMessage(error, props?.t);
        if (props?.setErrorMessage) {
          props?.setErrorMessage(errorMessage);
        }
        dispatch(failure(errorMessage));
      });

    function success(data) {
      return {
        type: transactionEnquiryTypes.GET_TRANSACTION_ENQUIRY_SUCCESS,
        data,
      };
    }

    function failure(error) {
      return {
        type: transactionEnquiryTypes.GET_TRANSACTION_ENQUIRY_ERROR,
        error,
      };
    }
  };
}

function getTransactionsForReject(transactionData, props) {
  return (dispatch) => {
    const user = store.getState()?.loginUserReducer.user;
    const { startOffset, endOffset, paymentFromDate, paymentToDate, ...rest } =
        transactionData;
    const updatedPaymentFromDate = moment(paymentFromDate).format();
    const updatedPaymentToDate = moment(paymentToDate).format();
    const token = store.getState()?.loginUserReducer.token;

    axiosRetry(axios, {
      retries: 5,
      retryDelay: (retryCount) => {
        if (retryCount < 5) {
          if (props?.setErrorMessage && props?.t) {
            props?.setErrorMessage(
                props?.t("erps.transactionEnquiry.page.pending")
            );
          }
        }

        return retryCount * (retryCount < 2 ? 20000 : 30000); // time interval between retries
      },
      retryCondition: (error) => {
        return error.response.status === 504;
      },
      onRetry: (retryCount, error, requestConfig) => {
        const requestId = error?.response?.data?.message?.split("::")[0]?.trim();
        requestConfig.data = requestConfig.data?.replace('"requestId":null', `"requestId":"${requestId}"`);
      }
    });

    axios
        .post(
            `${API_BASE_URL()}/search-transactions-for-reject`,
            {
              ...rest,
              ///bmoBillerId: rest.bmoBillerId,
              paymentFromDate: formatDate(updatedPaymentFromDate),
              paymentToDate: formatDate(updatedPaymentToDate),
              offset: startOffset,
              limit: 100,
              userId: user.userId,
              languageCode: user.languageCode,
              requestId: null,
              requestFrom:"rejectPaymentForMerchants"
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
              },
            }
        )
        .then((res) => {
          if (props?.setLoading) {
            props?.setLoading(false);
          }
          if (props?.setErrorMessage) {
            props?.setErrorMessage(null);
          }
          if (props?.setBtnLoading) {
            props?.setBtnLoading(false);
          }
          const response = {
            data: res.data,
            transactionForRejectDetails: {
              ...transactionData,
              paymentFromDate,
              paymentToDate,

            },
          };
            dispatch(success(response));
        })
        .catch((error) => {
          if (props?.setLoading) {
            props?.setLoading(false);
          }
          if (props?.setBtnLoading) {
            props?.setBtnLoading(false);
          }
          let errorMessage = getExceptionMessage(error, props?.t);
          if (props?.setErrorMessage) {
            props?.setErrorMessage(errorMessage);
          }
          dispatch(failure(errorMessage));
        });

    function success(data) {
      return {
        type: transactionEnquiryTypes.GET_TRANSACTION_FOR_REJECT_SUCCESS,
        data,
      };
    }

    function failure(error) {
      return {
        type: transactionEnquiryTypes.GET_TRANSACTION_FOR_REJECT_FAILURE,
        error,
      };
    }
  };
}

function udpateRecallAndRetrace(data, props) {
  console.log(data);
  return (dispatch) => {
    const { reqBodyForSendEmail, reqBodyForAddRecallAndRetrace } = data;
    const { fiNumber, clientName, userId, reqType, screenshot, language } =
      reqBodyForSendEmail;
    const formData = new FormData();
    formData.append("screenshot", screenshot);
    const token = store.getState()?.loginUserReducer.token;
    axios
      .post(
        `${API_BASE_URL()}/add-recalltrace-audit`,
        { ...reqBodyForAddRecallAndRetrace },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((re) => {
        axios
          .post(
            `${API_BASE_URL()}/upload-screenshot?fiNumber=${fiNumber}&clientName=${clientName}&userId=${userId}&reqType=${reqType}&language=${language}`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + token,
              },
            }
          )
          .then((res) => {
            if (props?.setLoading) {
              props?.setLoading(false);
            }

            if (props?.setShowSuccessMessage) {
              props?.setShowSuccessMessage(true);
            }
            dispatch(success(res.data));
          })
          .catch((error) => {
            if (props?.setLoading) {
              props?.setLoading(false);
            }
            if (props?.setShowSuccessMessage) {
              props?.setShowSuccessMessage(false);
            }
            let errorMessage = getExceptionMessage(error, props?.t);
            dispatch(failure(errorMessage));
          });
        function success(data) {
          return {
            type: transactionEnquiryTypes.UPDATE_RECALL_RETRACE_INFO_SUCCESS,
            data,
          };
        }

        function failure(error) {
          return {
            type: transactionEnquiryTypes.UPDATE_RECALL_RETRACE_INFO_FAILURE,
            error,
          };
        }
      })
      .catch((error) => {
        if (props?.setLoading) {
          props?.setLoading(false);
        }
        if (props?.setShowSuccessMessage) {
          props?.setShowSuccessMessage(false);
        }
        let errorMessage = getExceptionMessage(error, props?.t);
        dispatch(failure(errorMessage));
      });
    function failure(error) {
      return {
        type: transactionEnquiryTypes.UPDATE_RECALL_RETRACE_INFO_FAILURE,
        error,
      };
    }
  };
}

function cancelTransaction(data, props) {

  return (dispatch) => {
    const { reqBodyForCancel } = data;
    const user = store.getState()?.loginUserReducer.user;
    const token = store.getState()?.loginUserReducer.token;
    axios
        .post(
            `${API_BASE_URL()}/cancel-transaction`,
            { ...reqBodyForCancel
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
              },
            }
        ).then((res) => {
      if (props?.setLoading) {
        props?.setLoading(false);
      }
      console.log(res.data)
      if (props?.setShowSuccessMessage) {
        props?.setShowSuccessMessage(true);
      }
      dispatch(success(res.data));
    }).catch((error) => {
      if (props?.setLoading) {
        props?.setLoading(false);
      }
      if (props?.setShowSuccessMessage) {
        props?.setShowSuccessMessage(false);
      }
      let errorMessage = getExceptionMessage(error, props?.t);
      dispatch(failure(errorMessage));
    });

    function success(data) {
      return {
        type: transactionEnquiryTypes.UPDATE_TRANSACTION_FOR_PENDING_APPROVAL_SUCCESS,
        data,
      };
    }

    function failure(error) {
      return {
        type: transactionEnquiryTypes.UPDATE_TRANSACTION_FOR_PENDING_APPROVAL_FAILURE,
        error,
      };
    }
  };
}
function rejectTransaction(data, props) {

  return (dispatch) => {
    const { reqBodyForReject } = data;
    const user = store.getState()?.loginUserReducer.user;
    const token = store.getState()?.loginUserReducer.token;
    axios
        .post(
            `${API_BASE_URL()}/reject-transaction`,
            { ...reqBodyForReject
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
              },
            }
        ).then((res) => {
      if (props?.setLoading) {
        props?.setLoading(false);
      }

      if (props?.setShowSuccessMessage) {
        props?.setShowSuccessMessage(true);
      }
      dispatch(success(res.data));
    }).catch((error) => {
      if (props?.setLoading) {
        props?.setLoading(false);
      }
      if (props?.setShowSuccessMessage) {
        props?.setShowSuccessMessage(false);
      }
      let errorMessage = getExceptionMessage(error, props?.t);
          dispatch(failure(errorMessage));
        });

    function success(data) {
      return {
        type: transactionEnquiryTypes.UPDATE_TRANSACTION_FOR_REJECT_SUCCESS,
        data,
      };
    }

    function failure(error) {
      return {
        type: transactionEnquiryTypes.UPDATE_TRANSACTION_FOR_REJECT_FAILURE,
        error,
      };
    }
  };
}


function recallTransaction(data, props) {

  return (dispatch) => {
    const { reqBodyForRecall } = data;
    const user = store.getState()?.loginUserReducer.user;
    const token = store.getState()?.loginUserReducer.token;
    axios
        .post(
            `${API_BASE_URL()}/recall-transaction`,
            { ...reqBodyForRecall
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
              },
            }
        ).then((res) => {
      if (props?.setLoading) {
        props?.setLoading(false);
      }

      if (props?.setShowSuccessMessage) {
        props?.setShowSuccessMessage(true);
      }
      dispatch(success(res.data));
    }).catch((error) => {
      if (props?.setLoading) {
        props?.setLoading(false);
      }
      if (props?.setShowSuccessMessage) {
        props?.setShowSuccessMessage(false);
      }
      let errorMessage = getExceptionMessage(error, props?.t);
          dispatch(failure(errorMessage));
        });

    function success(data) {
      return {
        type: transactionEnquiryTypes.UPDATE_TRANSACTION_FOR_RECALL_SUCCESS,
        data,
      };
    }

    function failure(error) {
      return {
        type: transactionEnquiryTypes.UPDATE_TRANSACTION_FOR_RECALL_FAILURE,
        error,
      };
    }
  };
}

function searchRecallRejectHistory(transactionData, props) {
  return (dispatch) => {
    const user = store.getState()?.loginUserReducer.user;
    const fiDetail = store.getState()?.fiReducer;
    const token = store.getState()?.loginUserReducer.token;

    axios
      .post(
        `${API_BASE_URL()}/searchRecallRejectHistory`,
        { userId: user.userId,
          languageCode: user.languageCode,
          roleType: user?.role?.role_type,

          ...transactionData
          // transactionStatus: transactionData.transactionStatus,
          // selectedFiNumber: fiDetail?.selectedFi,
          // offset: transactionData.startOffset,
          // limit: transactionData.limit,
          // requestId: null
        },
        { headers: { "Content-Type": "application/json", Authorization: "Bearer " + token, } }
      )
      .then((res) => {
        if (props?.setLoading)
          props?.setLoading(false);
        if (props?.setErrorMessage)
          props?.setErrorMessage(null);
        const response = {
          data: res.data,
          recallRejectHistoryDetails: { ...transactionData },
        };
        dispatch(success(response));
      })
      .catch((error) => {
        if (props?.setLoading)
          props?.setLoading(false);
        let errorMessage = getExceptionMessage(error, props?.t);
        if (props?.setErrorMessage) {
          props?.setErrorMessage(errorMessage);
        }
        dispatch(failure(errorMessage));
      });
    
    function success(data) {
      return {
        type: "GET_RECALL_REJECT_HISTORY_SUCCESS",
        data,
      };
    }

    function failure(error) {
      return {
        type: "GET_RECALL_REJECT_HISTORY_ERROR",
        error,
      };
    }

  };
}
