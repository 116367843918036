import axios from "axios";
import store from "../store/index";
import { getExceptionMessage, API_BASE_URL    , listProps } from "../utils/helper";

export const recallPendingApprovalsService = {
    searchRecallPendingApprovals,
};

function searchRecallPendingApprovals(transactionData, props) {
    return (dispatch) => {
        const user = store.getState()?.loginUserReducer.user;
        const fiDetail = store.getState()?.fiReducer;
        const token = store.getState()?.loginUserReducer.token;

        const { startOffset, endOffset, limit, ...rest } = transactionData;

        axios
            .post(
                `${API_BASE_URL()}/searchRecallPendingApprovals`,
                { fi_number: user.fiNumber,
                    offset: transactionData.startOffset,
                    limit: transactionData.limit
                },
                { headers: { "Content-Type": "application/json", Authorization: "Bearer " + token, } }
            )
            .then((res) => {
                if (props?.setLoading)
                    props?.setLoading(false);
                if (props?.setErrorMessage)
                    props?.setErrorMessage(null);
                const response = {
                    data: res.data,
                    recallPendingApprovalsDetails: {
                        ...transactionData,
                    },
                };
                dispatch(success(response));
            })
            .catch((error) => {
                console.log("RPA.service - .catch()   error: " + error);
                if (props?.setLoading)
                    props?.setLoading(false);
                let errorMessage = getExceptionMessage(error, props?.t);
                if (props?.setErrorMessage) {
                    props?.setErrorMessage(errorMessage);
                }
                dispatch(failure(errorMessage));
            });

        function success(data) {
            return {
                type: "GET_RECALL_PENDING_APPROVALS_SUCCESS",
                data,
            };
        }

        function failure(error) {
            return {
                type: "GET_RECALL_PENDING_APPROVALS_ERROR",
                error,
            };
        }

    };
}