import ERPSBaseLink from "../components/custom/inputs/ERPSBaseLink";
import { t } from "i18next";
import { convertDateIntoERPSDateFormat, formatAmount   ,listProps } from "../utils/helper";
import { transactionStatusMap } from "./sharedConstants";

export const recallRejectHistoryHeading = (componentAccess, financialInstitutionMap) => {
    return [
      { field: "id",
        headerName: t("erps.common.label.number.short"),
        width: 40,
        sortable: false,
        renderCell: (params) => (
          <ERPSBaseLink className="link__blue"
            to={ { pathname: `/recall-reject-history/recall-reject-details`, state: { id: params.row?.id } }}
            label={ "" + params.row?.id } />
          ),
      },
      { field: "transaction_amount",
        headerName: t("erps.common.label.paymentAmount"),
        width: 135,
        sortable: false,
        renderCell: (params) => { if (params.value)  return formatAmount(params.value); },
      },
      { field: "effective_date",
        headerName: t("erps.common.label.effectiveDate"),
        width: 110,
        sortable: false,
        renderCell: (params) => convertDateIntoERPSDateFormat(params.value),
      },
      { field: "verification_number",
        headerName: t("erps.common.label.confirmationNumber"),
        width: 180,  // 160
        sortable: false,
      },
      { field: "display_biller_id",
        headerName: t("erps.common.label.billerID"),
        width: 120,
        sortable: false,
      },
      { field: "fi_customer_number",
        headerName: t("erps.common.label.customerAccountNumber"),
        width: 200,
        sortable: false,
      },
      { field: "fi_number",
        headerName: t("erps.common.label.fi"),
        width: 200,
        sortable: false,
        renderCell: (params) => financialInstitutionMap?.get(params.value) || params.value,
      },
      { field: "transaction_status",
        headerName: t("erps.common.label.status"),
        width: 180,
        sortable: false,
        renderCell: (params) => transactionStatusMap.get(params.value),
      },
    ];
  };
  
  export const EMPTY_SEARCH_CRITERIA = {  // WHERE IS THIS USED ??
    transactionType: null,
    paymentFromDate: null,
    paymentToDate: null,
    transactionStatus: null,
    fiNumber: "0",
    searchBy: null,
    bmoBillerId: "",
    billerName: "",
    billerCustomerNumber: "",
    fiCustomerName: "",
    startOffset: 0,
    //endOffset: 100,
    limit: 100,
  };
  