import services from "../services";

export const recallPaymentCustomerActions = {
  getRPC,
};

function getRPC(values, props) {
  return services.recallPaymentCustomerService.getRPC(
    values,
    props
  );
}

