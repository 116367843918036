import React from "react";
import { withSidebar } from "../hoc/withSidebar";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { Box } from "@material-ui/core";
import ERPSBaseButton from "../custom/inputs/ERPSBaseButton";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { useSelector } from "react-redux";
import ERPSTransactionDetails from "../custom/ERPSTransactionDetails";

const RecallRejectDetails = (props) => {
    const {
        location: { state },
    } = props;
    const { t } = useTranslation();
    const transactionListData = useSelector( 
        (state) => state.transactionEnquiryReducer?.upcomingRecallsRejectsData?.data
    );

    let data = transactionListData ? transactionListData.find(({id}) => id == state.id) : {};
    
    return (
        <div role="main">
            <Box st={{ ml: 3}}>
                <Breadcrumb {...props} fromDetailPage={true}/>
            </Box>

            <ERPSTransactionDetails data={data} />

            {data?.transaction_type && (
                <Box sx={{ ml: 3, mt: 3, display: "flex" }}>
                    <Typography className="transaction__enquiry__value">
                        {t(data?.transaction_type == "RJ" ? "erps.rejectpaymentmerchants.page.label.reject.reason" : "erps.transactionEnquiry.label.recall.reason")}
                        <Typography variant="body2">{data.recall_reject_reason}</Typography>
                    </Typography>
                    <Typography className="transaction__enquiry__value left_space">
                        {t(data?.transaction_type == "RJ" ? "erps.rejectpaymentmerchants.page.label.rejected.by" : "erps.transactionEnquiry.label.recalled.by")}
                        <Typography variant="body2">{data.recalled_rejected_by}</Typography>
                    </Typography>
                </Box>
            )}

            <Box display="flex" sx={{ spacing: 2, ml: 3}}>
                <ERPSBaseButton
                    variant="secondary"
                    className="button_with_margin48"
                    label={t("erps.newuser.button.back", "Back")}
                    onClick={() =>
// SHOULDN'T THIS BE A PARAMETER IF THIS IS RE-USED BY OTHER SCREENS ??
                        props.history.push("/upcoming-recalls-rejects/", { fromDetailPage: true })
                    }
                />
                <Box sx={{ pl: 2 }}>
                <ERPSBaseButton
// See TransactionDetail.js for printing functionality
                    variant="primary"
                    className="button_with_margin48"
                    label={t("erps.transactionEnquiry.page.transactionDetails.button.print")}
                    onClick={() => {alert("Will print eventually");}}
                    //loading={rejectLoading}
                />
                </Box>
            </Box>
        </div>
    );
};

export default withSidebar(RecallRejectDetails);
