import React, { useEffect, useState, useRef } from "react";
import ERPSBaseTextField from "../custom/inputs/ERPSBaseTextField";
import ERPSBaseRadioGroup from "../custom/inputs/ERPSBaseRadioGroup";
import ERPSBaseCheckBoxGrp from "../custom/inputs/ERPSBaseCheckBoxGrp";
import Stack from "@mui/material/Stack";
import ERPSBaseButton from "../custom/inputs/ERPSBaseButton";
import { useTranslation } from "react-i18next";
import { Box } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import { useSelector, useDispatch } from "react-redux";
import ERPSBaseDialog from "../custom/inputs/style/ERPSBaseModelDialogStyle";
import DialogHeader from "../custom/inputs/ERPSBaseModelDialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { userActions } from "../../actions/user.actions";
import { userPendingActions } from "../../actions/user.pending.actions";
import { billerProfileActions } from "../../actions/billerProfile.action";
import ERPSBaseLink from "../custom/inputs/ERPSBaseLink";
import ErrorMessage from "../custom/containers/ErrorMessage";
import { getServiceAgreement, checkFieldsChange } from "../../utils/helper";

import userActionTypes from "../../actions/types/user.action.type";
import Alert from "@mui/material/Alert";

const UserProfile = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loginUser = useSelector((state) => state.loginUserReducer?.user);
  const wpBillerAdmin = 3;
  const wpBillerUser = 4;
  const fiSessionTimeOut = loginUser?.maxFiSessionTimeOut || 20;

  const userResult = useSelector((state) => state.userDetailReducer.userData);
  const user = useSelector((state) => state.loginUserReducer.user);
  const selectedFiNumber = useSelector((state) => state.fiReducer?.selectedFi);

  const userRoleTypes = useSelector((state) => state.userRoleTypeReducer);
  const subscribeEntities = useSelector(
    (state) => state.subscribingEntitiesReducer
  );
  const billerProfile = useSelector((state) => state.billerProfileReducer);
  const [userRoleListDropdown, setUserRoleListDropdown] = useState(null);
  const [errorMessage, setErrorMessage] = useState([]);
  const [subscribeEntityList, setSubscribeEntityList] = useState([]);
  const roleTypeOfLoggedInUser = user?.role?.role_type;
  const [billerProfileData, setBillerProfileData] = useState(null);
  const [hasShowWarningMessage, setShowWarningMessage] = useState(false);
  const { componentAccess, parentComponentAccess, personalUser } = props;
  const userProfileAccess = componentAccess.find(
    ({ componentType }) => componentType === "UPTP"
  );
  const billerUserProfileAccess = componentAccess.find(
    ({ componentType }) => componentType === "BUP"
  );
  const [hasFIAdminUser, setHasFIAdminUser] = useState(false);
  const fiNumberValue = selectedFiNumber ? selectedFiNumber : user?.fiNumber;

  const [emailHighlight, setEmailHighlight] = useState(props.emailHighlight);

  //BugNo:5809 | Modified By:Mohana lakshmi Moka | Modified Date:23/August/2024
  useEffect(() => {
    window.history.pushState(null, '', window.location.href);
    const handlePopState = (event) => {
      window.history.pushState(null, '', window.location.href);
    };
    window.addEventListener('popstate', handlePopState);
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);
  //End

  const updatedDate = (date) =>
    date?.toString().replace(/(\d{4})(\d{2})(\d{2})/g, "$1/$2/$3");

  useEffect(() => {
    if (props.pageName === "manage-biller") {
      const data = [
        {
          value: "3",
          roleType: "MA",
          label: t("erps.common.label.wpbilleradmin"),
        },
        {
          value: "4",
          roleType: "MU",
          label: t("erps.common.label.wpbilleruser"),
        },
      ];
      data.unshift({ roleId: "", value: "", label: "" });
      setUserRoleListDropdown(data);
      const hasFIAdminUser = user?.role?.role_id == "7";
      setHasFIAdminUser(true);
    } else if (userRoleTypes) {
      if (userRoleTypes.userRoleTypeList) {
        const data = userRoleTypes.userRoleTypeList.map(
          ({ role_id, role_type, role_name }) => ({
            roleType: role_type,
            value: role_id,
            label: role_name,
          })
        );
        if (props?.pageName === "account-settings" && user.role.role_id == 5) {
          data.unshift({
            roleType: "PA",
            value: 5,
            label: "ERPADMIN",
          });
        }
        data.unshift({
          roleType: "",
          value: "",
          label: "",
        });

        setUserRoleListDropdown(data);
      }
    }
    if (userRoleTypes?.error) {
      setErrorMessage([userRoleTypes.error]);
    }
  }, [userRoleTypes]);

  useEffect(() => {
    if (billerProfile) {
      if (billerProfile.billerProfileData) {
        setBillerProfileData(billerProfile.billerProfileData.data);
      } else if (billerProfile.billerProfileAssociatedData) {
        setBillerProfileData(billerProfile.billerProfileAssociatedData);
      }
    }
    if (billerProfile?.billerProfileError) {
      setErrorMessage([billerProfile.billerProfileError]);
    }
  }, [billerProfile]);

  useEffect(() => {
    if (subscribeEntities) {
      if (subscribeEntities.subscribingEntitiesList) {
        const data = subscribeEntities.subscribingEntitiesList.map(
          ({ fiCompanyNumber, fiCompanyName }) => ({
            value: fiCompanyNumber,
            label: fiCompanyName,
          })
        );
        data.unshift({
          value: "",
          label: "",
        });
        setSubscribeEntityList(data);
      }
    }
    if (subscribeEntities?.error) {
      setErrorMessage([subscribeEntities.error]);
    }
  }, [subscribeEntities]);

  useEffect(() => {
    dispatch(
      userActions.getUserRoleTypeList(
        user?.fiNumber,
        roleTypeOfLoggedInUser,
        "manageusercomponent",
        t,
        null,
        personalUser
      )
    );
  }, []);

  useEffect(() => {
    if (fiNumberValue == 99 || fiNumberValue == 88) {
      dispatch(userActions.getSubscribingEntitiesList(fiNumberValue, t));
    }
  }, []);

  useEffect(() => {
    if (values.billerId && props.pageName !== "manage-biller") {
      dispatch(
        billerProfileActions.getBillerProfile(values.billerId, user?.fiNumber, {
          t,
        })
      );
    }
  }, []);

  const errorRef = useRef();
  const warningRef = useRef();

  useEffect(() => {
    if (errorRef.current) {
      errorRef.current.focus();
    }
  }, [errorMessage]);
  useEffect(() => {
    if (warningRef.current) {
      warningRef.current.focus();
    }
  }, [hasShowWarningMessage]);

  const getUserData = () => {
    let data = null;
    if (
      props.pageName === "account-registration" ||
      props.pageName === "account-settings"
    ) {
      data = {
        userId: user.userId,
        firstName: user.firstName,
        internalBMOUserId: user.internalBMOUserId,
        lastName: user.lastName,
        emailAddress: user.emailAddress,
        phone: user.phone,
        role: user.role.role_name,
        statusCode: user.statusCode,
        roleId: user.role.role_id,
        languageCode: user.languageCode,
        fiCompanyNumber: user.fiCompanyNumber,
        challengeNumber: user.challengeNumber,
        challengeResponse: user.challengeResponse,
        fiNumber: selectedFiNumber || user.fiNumber,
        roleType: user.role.role_type,
        changePasswordIndex: "N",
        logonFailures: 0,
        passwordHash: "",
        billerId: user.billerNonVolatileDto?.biller_id,
        billerAccountNumber: user.billerNonVolatileDto?.debit_account_number,
        emailNotificationOk:
          props.pageName == "account-settings" &&
          user.role.role_id == wpBillerUser
            ? user.emailNotificationOk
            : user.billerNonVolatileDto?.email_notification_ok,
        csvAdviceOk: user.billerNonVolatileDto?.csv_advice_ok,
        sessionTimeOut: user?.sessionTimeOut || fiSessionTimeOut,
      };
    } else {
      data = userResult
        ? userResult.find(
            ({ userId, internalBMOUserId }) =>
              internalBMOUserId === props.profileId ||
              userId === props.profileId
          )
        : {};
    }

    return data;
  };
  const getBillerInfo = () => {
    let data;
    if (props.pageName === "manage-biller") {
      data = {
        ...billerProfileData,
        ...billerProfileData?.billerNonVolatileDto,
      };
    } else if (
      (props.pageName === "account-settings" ||
        props.pageName === "account-registration") &&
      user.billerNonVolatileDto
    ) {
      data = { ...user.billerNonVolatileDto, ...user.searchBillerFilesDto };
    } else if (props.pageName === "manage-users") {
      data = getUserData()?.billerNonVolatileDto;
    }
    return data;
  };
  const getPdfFile = () => {
    let serviceAgreement = "";
    if (billerProfileData) {
      if (props.pageName === "account-settings") {
        serviceAgreement = getServiceAgreement(user);
      } else {
        const creditorFiNumber =
          getBillerInfo()?.credit_fi_number ||
          billerProfileData?.creditorFiNumber;
        serviceAgreement = getUserData()
          ? getServiceAgreement({
              ...getUserData(),
              creditorFiNumber,
            })
          : "";
      }
    }

    if (serviceAgreement) {
      if (user?.languageCode === "F") {
        return serviceAgreement.french;
      } else {
        return serviceAgreement.english;
      }
    } else {
      return "";
    }
  };

  const {
    userId,
    firstName,
    lastName,
    emailAddress,
    phone,
    roleName,
    statusCode,
    roleId,
    languageCode,
    challengeNumber,
    challengeResponse,
    fiNumber,
    roleType,
    fiCompanyNumber,
    logonFailures,
    changePasswordIndex,
    passwordHash,
    billerId,
    billerAccountNumber,
    emailNotificationOk,
    csvAdviceOk,
    internalBMOUserId,
    billerNonVolatileDto,
    sessionTimeOut,
  } = getUserData();

  const getEmailNotificationValue = () => {
    if (
      roleId == wpBillerUser &&
      (props.pageName === "manage-biller" ||
        props.pageName === "account-settings" ||
        props.pageName === "manage-users")
    ) {
      return emailNotificationOk;
    } else {
      return (
        getBillerInfo()?.emailNotificationOk ||
        getBillerInfo()?.email_notification_ok
      );
    }
  };

  const [values, setValues] = useState({
    userID: internalBMOUserId || userId,
    fiNumber: fiNumber,
    roleId: roleId,
    roleType: roleType,
    roleName: roleName,
    languageCode: languageCode,
    fiCompanyNumber: fiCompanyNumber,
    firstname: firstName,
    lastname: lastName,
    emailAddress: emailAddress,
    phone: phone,
    status: statusCode,
    challengeNumber: challengeNumber,
    challengeResponse: challengeResponse,
    fiCompanyNumber: fiCompanyNumber,
    logonFailures: logonFailures,
    changePasswordIndex: changePasswordIndex,
    passwordHash: passwordHash,
    billerId: billerId,
    billerAccountNumber: billerAccountNumber,
    emailNotificationOk: getEmailNotificationValue(),
    sessionTimeOut: sessionTimeOut,
    sessionTimeOutErrorHighlight: !sessionTimeOut,
    phoneErrorHighlight: false,
    firstNameErrorHighlight: !firstName,
    lastNameErrorHighlight: !lastName,
    emailAddressHighlight: !emailAddress,
    challangeResponseErrorHighlight: false,
    challengeNumberErrorHighlight: false,
    csvAdviceOk: getBillerInfo()?.csvAdviceOk || getBillerInfo()?.csv_advice_ok,
  });
  const [open, setOpen] = useState(false);
  const [openDialogForRadio, setOpenDialogForRadio] = useState(false);
  const [openDialogForEmail, setOpenDialogForEmail] = useState(false);
  const [dialogLoading, setDialogLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  const languageItems = [
    {
      name: t("erps.userprofile.language.english.text", "English"),
      value: "E",
    },
    { name: t("erps.userprofile.language.french.text", "French"), value: "F" },
  ];

  const documentFormatItems = [
    { name: t("erps.userprofile.language.pdf.text", "PDF"), value: "N" },
    {
      name: t("erps.userprofile.language.pdfandcsv.text", "PDF and CSV"),
      value: "Y",
    },
  ];

  const fiList = [
    { value: "99", label: "BMO" },
    { value: "10", label: "CIBC" },
    { value: "2", label: "BNS" },
    { value: "50", label: "TELUS" },
  ];
  const statusList = [
    { value: "I", label: t("erps.manageusers.label.status.inactive") },
    { value: "A", label: t("erps.manageusers.label.status.active") },
  ];

  const challengeQuesList = [
    {
      value: 0,
      label: "",
    },
    {
      value: 1,
      label: t(
        "erps.userprofile.challengequestion1.label",
        "What is your mother's maiden name?"
      ),
    },
    {
      value: 2,
      label: t(
        "erps.userprofile.challengequestion2.label",
        "What is your's pet name?"
      ),
    },
    {
      value: 3,
      label: t(
        "erps.userprofile.challengequestion3.label",
        "Where is your favourite hoilday destination?"
      ),
    },
  ];

  const handleChange = (event, key) => {
    if (errorMessage === t("erps.manageusers.error.duplicate.emailaddress")) {
      setErrorMessage("");
    }
    const webPortalRolesId = [1, 2, 3];
    if (key === "csvAdviceOk" && event.target.value === "Y") {
      if (
        webPortalRolesId.includes(values?.roleId) &&
        fiNumberValue != 10 &&
        props.pageName !== "account-settings"
      ) {
        setOpenDialogForRadio({ isOpen: true, key, value: event.target.value });
      } else if (
        webPortalRolesId.includes(values?.roleId) &&
        (fiNumberValue == 10 || fiNumberValue == 2)
      ) {
        setOpenDialogForRadio({ isOpen: true, key, value: event.target.value });
      } else {
        setValues({ ...values, [key]: event.target.value });
      }
    } else if (key === "emailNotificationOk") {
      if (
        roleId == 3 &&
        (props.pageName == "manage-biller" ||
          props.pageName == "account-settings" ||
          props.pageName == "manage-users") &&
        fiNumberValue == 2 &&
        event.target.checked
      ) {
        setOpenDialogForEmail({
          isOpen: true,
          key,
          value: event.target.checked ? "Y" : "N",
        });
      } else {
        setValues({ ...values, [key]: event.target.checked ? "Y" : "N" });
      }
    } else {
      setValues({ ...values, [key]: event.target.value });
    }
  };
  const checkValuesChanged = () => {
    const {
      firstName,
      lastName,
      emailAddress,
      phone,
      roleName,
      statusCode,
      roleId,
      languageCode,
      challengeNumber,
      challengeResponse,
      roleType,
      fiCompanyNumber,
      csvAdviceOk,
      sessionTimeOut,
    } = getUserData();
    const postValues = {
      firstName: values.firstname,
      lastName: values.lastname,
      challengeNumber: values.challengeNumber,
      challengeResponse: values.challengeResponse,
      phone: values.phone,
      languageCode: values?.languageCode || user.languageCode,
      emailAddress: values.emailAddress,
      roleId: values.roleId,
      roleType: values.roleType,
      fiCompanyNumber: values.fiCompanyNumber,
      statusCode: values.status,
      csvAdviceOk: values.csvAdviceOk || csvAdviceOk,
      emailNotificationOk: getCheckboxEmailValue() ? "Y" : "N",
      sessionTimeOut: values?.sessionTimeOut,
    };
    const preValues = {
      firstName,
      lastName,
      emailAddress,
      phone,
      roleName,
      statusCode,
      roleId,
      languageCode,
      challengeNumber,
      challengeResponse,
      roleType,
      fiCompanyNumber,
      emailNotificationOk: getEmailNotificationValue(),
      csvAdviceOk,
      sessionTimeOut,
    };
    return checkFieldsChange(preValues, postValues);
  };
  const handleDeleteUser = () => {
    setSaveLoading(true);
    setOpen(true);
    const userDetailUpdated = {
      userId: values.userID,
      loggedInUserId: user.userId,
      fiNumber: values.fiNumber,
      firstName: values.firstname,
      lastName: values.lastname,
      challengeNumber: values.challengeNumber,
      challengeResponse: values.challengeResponse,
      phone: values.phone,
      languageCode: user.languageCode,
      statusCode: values.status,
      emailAddress: values.emailAddress,
      roleId: values.roleId,
      roleType: values.roleType,
      fiCompanyNumber: values.fiCompanyNumber ? values.fiCompanyNumber : "0",

      logonFailures: values.logonFailures ? values.logonFailures : 0,
      changePasswordIndex: values.changePasswordIndex
        ? values.changePasswordIndex
        : "N",
      passwordHash: values.passwordHash ? values.passwordHash : "",
      sessionTimeOut: values?.sessionTimeOut,
    };
    dispatch(
      userPendingActions.deleteUserStatusPendingQueue(userDetailUpdated, {
        setSaveLoading,
        setOpen,
        setErrorMessage,
        setShowMessage: props.setShowMessage,
        t,
        history: props.history,
        pageName: props?.pageName,
        handleClickLink: props?.handleClickLink,
      })
    );
  };
  const navigationToMangerUserPage = (event) => {
    event.preventDefault();
    const {
      userId,
      firstName,
      lastName,
      emailAddress,
      phone,
      roleName,
      statusCode,
      roleId,
      languageCode,
      challengeNumber,
      challengeResponse,
      fiNumber,
      roleType,
      fiCompanyNumber,
      logonFailures,
      changePasswordIndex,
      passwordHash,
      billerId,
      billerAccountNumber,
      emailNotificationOk,
      csvAdviceOk,
      internalBMOUserId,
      billerNonVolatileDto,
      sessionTimeOut,
    } = getUserData();
    setValues({
      userID: internalBMOUserId || userId,
      fiNumber: fiNumber || "",
      roleId: roleId || "",
      roleType: roleType || "",
      roleName: roleName || "",
      languageCode: languageCode || "",
      fiCompanyNumber: fiCompanyNumber || "",
      firstname: firstName || "",
      lastname: lastName || "",
      emailAddress: emailAddress || "",
      phone: phone || "",
      status: statusCode || "",
      challengeNumber: challengeNumber,
      challengeResponse: challengeResponse || "",
      fiCompanyNumber: fiCompanyNumber || "",
      logonFailures: logonFailures,
      changePasswordIndex: changePasswordIndex,
      passwordHash: passwordHash,
      billerId: billerId,
      billerAccountNumber: billerAccountNumber,
      emailNotificationOk: getEmailNotificationValue(),
      csvAdviceOk:
        getBillerInfo()?.csvAdviceOk || getBillerInfo()?.csv_advice_ok,
      sessionTimeOut: sessionTimeOut || fiSessionTimeOut,
      sessionTimeOutErrorHighlight: !sessionTimeOut,
      phoneErrorHighlight: false,
      firstNameErrorHighlight: !firstName,
      lastNameErrorHighlight: !lastName,
      emailAddressHighlight: !emailAddress,
      challangeResponseErrorHighlight: false,
      challengeNumberErrorHighlight: false,
    });
    if (hasShowWarningMessage) {
      setShowWarningMessage(false);
    }
    setErrorMessage("");
  };
  const validateEmail = (email) => {
    var regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regexEmail.test(email);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleSave = () => {
    setEmailHighlight(false);
    const errormsg = validateUser();
    setErrorMessage(errormsg);
    if (!errormsg.length) {
      const userDetailUpdated = {
        userId: values.userID,
        billerId: values.billerId,
        loggedInUserId: user.userId,
        fiNumber: values.fiNumber,
        firstName: values.firstname,
        lastName: values.lastname,
        challengeNumber: values.challengeNumber,
        challengeResponse: values.challengeResponse,
        phone: values.phone,
        languageCode: values?.languageCode || user.languageCode,
        emailAddress: values.emailAddress,
        roleId: values.roleId,
        roleType: values.roleType,
        fiCompanyNumber: values.roleId == "9" ? values.fiCompanyNumber : "",
        statusCode: values.status,
        logonFailures: values.logonFailures,
        changePasswordIndex: values.changePasswordIndex,
        passwordHash: values.passwordHash,
        csvAdviceOk: values.csvAdviceOk,
        sessionTimeOut: values.sessionTimeOut,
      };
      setOpen(false);
      setDialogLoading(true);
      if (props.pageName === "account-settings") {
        userDetailUpdated.fiNumber = user.fiNumber;
        userDetailUpdated.billerId = user?.billerNonVolatileDto?.biller_id;
        userDetailUpdated.emailNotificationOk = values.emailNotificationOk;
        dispatch(
          userActions.updateUserDetails(userDetailUpdated, {
            setDialogLoading,
            setErrorMessage,
            setShowMessage: props.setShowMessage,
            t,
          })
        );
      } else {
        userDetailUpdated.emailNotification = values.emailNotificationOk;
        if (props.setShowMessage) {
          props.setShowMessage(false);
        }
        dispatch({
          type: userActionTypes.USER_QUEUE_DELETE_ERROR,
          error: null,
        });
        const hasFieldsChanged = checkValuesChanged();
        if (hasFieldsChanged) {
          if (hasShowWarningMessage) {
            setShowWarningMessage(false);
          }
          dispatch(
            userPendingActions.updateUserStatusPendingQueue(userDetailUpdated, {
              setDialogLoading,
              setShowMessage: props.setShowMessage,
              t,
              history: props.history,
              pageName: props?.pageName,
              handleClickLink: props?.handleClickLink,
              setErrorMessage,
            })
          );
        } else {
          setShowWarningMessage(true);
          setDialogLoading(false);
        }
      }
    }
  };
  const handleClose = () => {
    if (open) {
      setOpen(false);
    } else if (openDialogForRadio && openDialogForRadio?.isOpen) {
      setOpenDialogForRadio(false);
    } else if (openDialogForEmail && openDialogForEmail?.isOpen) {
      setOpenDialogForEmail(false);
    }
  };

  function validateUser() {
    let errormsg = [];
    var alphaNumericRegEx = /^[0-9A-Za-z]+$/;
    var alphaNumericRegExWithSpace = /^[a-zA-Z0-9.\-' À-ÿ]+$/;
    var numericRegEx = /^[0-9]+$/;
    values.phoneErrorHighlight = false;
    values.firstNameErrorHighlight = false;
    values.lastNameErrorHighlight = false;
    values.emailAddressHighlight = false;
    values.challangeResponseErrorHighlight = false;
    values.challengeNumberErrorHighlight = false;
    values.roleIdErrorHighlight = false;
    values.sessionTimeOutErrorHighlight = false;

    if (!values.firstname) {
      values.firstNameErrorHighlight = true;
      errormsg.push(
        t("erps.manageusers.blank.firstname.msg", "First name cannot be blank.")
      );
    }
    if (props.pageName === "account-settings") {
      if (!values.sessionTimeOut) {
        values.sessionTimeOutErrorHighlight = true;
        errormsg.push(t("erps.manageusers.blank.sessionTimeOut.msg"));
      } else if (values.sessionTimeOut < 1) {
        values.sessionTimeOutErrorHighlight = true;
        errormsg.push(t("erps.manageusers.notZero.sessionTimeOut.msg"));
      } else if (values.sessionTimeOut > fiSessionTimeOut) {
        values.sessionTimeOutErrorHighlight = true;
        errormsg.push(
          t("erps.manageusers.greaterThanFiValue.sessionTimeOut.msg", {
            fiSessionTimeOut: fiSessionTimeOut,
          })
        );
      }
    }

    if (
      values.firstname &&
      (values.firstname.trim().length < 1 ||
        values.firstname.trim().length > 25)
    ) {
      values.firstNameErrorHighlight = true;
      errormsg.push(
        t(
          "erps.manageusers.length.firstname.msg",
          "First name length is between 1 and 25 characters."
        )
      );
    }
    if (
      values.firstname &&
      values.firstname.match(alphaNumericRegExWithSpace) === null
    ) {
      values.firstNameErrorHighlight = true;
      errormsg.push(
        t(
          "erps.manageusers.alphanumeric.firstname.msg",
          "First name must be alpha numeric."
        )
      );
    }

    if (!values.lastname) {
      values.lastNameErrorHighlight = true;
      errormsg.push(
        t("erps.manageusers.blank.lastname.msg", "Last name cannot be blank.")
      );
    }
    if (
      values.lastname &&
      (values.lastname.trim().length < 1 || values.lastname.trim().length > 25)
    ) {
      values.lastNameErrorHighlight = true;
      errormsg.push(
        t(
          "erps.manageusers.length.lastname.msg",
          "Last name length is between 1 and 25 characters."
        )
      );
    }
    if (
      values.lastname &&
      values.lastname.match(alphaNumericRegExWithSpace) === null
    ) {
      values.lastNameErrorHighlight = true;
      errormsg.push(
        t(
          "erps.manageusers.alphanumeric.lastname.msg",
          "Last name  must be alpha numeric."
        )
      );
    }

    if (values.phone && values.phone.trim().length !== 10) {
      values.phoneErrorHighlight = true;
      errormsg.push(
        t("erps.manageusers.length.phone.msg", "Phone length should be 10.")
      );
    }
    if (values.phone && values.phone.match(numericRegEx) === null) {
      values.phoneErrorHighlight = true;
      errormsg.push(
        t("erps.manageusers.numeric.phone.msg", "Phone Must be numeric.")
      );
    }
    if (values.emailAddress && values.emailAddress.length > 80) {
      values.emailAddressHighlight = true;
      errormsg.push(
        t(
          "erps.manageusers.length.emailaddress.msg",
          "Email address length should not be greater than 60 characters."
        )
      );
    }
    if (!validateEmail(values.emailAddress)) {
      values.emailAddressHighlight = true;
      errormsg.push(
        t(
          "erps.manageusers.format.emailaddress.msg",
          "Please enter your e-mail address in this format 'name@example.com'"
        )
      );
    }

    if (values.roleId == "9" && !values.fiCompanyNumber.trim()) {
      errormsg.push(t("erps.manageusers.subscribingentity.msg"));
    }

    if (
      props.pageName === "account-registration" ||
      props.pageName === "account-settings"
    ) {
      if (!values.challengeNumber || values.challengeNumber == 0) {
        values.challengeNumberErrorHighlight = true;
        errormsg.push(
          t(
            "erps.manageusers.blank.challengeQuestion.msg",
            "Challenge question name cannot be blank."
          )
        );
      }
      if (!values.challengeResponse) {
        values.challangeResponseErrorHighlight = true;
        errormsg.push(
          t(
            "erps.manageusers.blank.challengeresponse.msg",
            "Challenge response cannot be blank."
          )
        );
      }
      if (
        values.challengeResponse &&
        (!values.challengeResponse.trim().length ||
          values.challengeResponse.trim().length > 60)
      ) {
        values.challangeResponseErrorHighlight = true;
        errormsg.push(
          t(
            "erps.manageusers.length.challengeresponse.msg",
            "Challenge response should not be greater than 60 characters."
          )
        );
      }
      if (
        values.challengeResponse &&
        values.challengeResponse.match(alphaNumericRegExWithSpace) === null
      ) {
        values.challangeResponseErrorHighlight = true;
        errormsg.push(
          t(
            "erps.manageusers.alpha.challengeResponse.msg",
            "Challenge response name must be alpha."
          )
        );
      }
    }
    if (
      !(
        props.pageName === "account-settings" ||
        props.pageName === "account-registration" ||
        hasFIAdminUser
      )
    ) {
      if (!values?.roleId) {
        values.roleIdErrorHighlight = true;

        errormsg.push(t("erps.manageusers.blank.roleId.cannotblank.msg"));
      }
    }
    return errormsg;
  }

  const showSubscribingEntity = () => {
    if (props.pageName === "account-settings") {
      return values.roleId == "9" && values.roleType === "CA";
    } else if (
      props.pageName === "manage-users" ||
      props.pageName === "manage-biller"
    ) {
      return values.roleId == "9";
    }
  };
  const getDialogHeader = () => {
    if (open) {
      return `${t("erps.manageusers.model.title", "Delete user profile:")} ${
        values.userID
      }`;
    } else if (openDialogForRadio || openDialogForEmail) {
      return `${window.location.host} ${t(
        "erps.manageusers.pendingUser.heading.dialog.additional.text"
      )}`;
    }
  };
  const getDialogContent = () => {
    if (open) {
      return (
        <>
          <Typography gutterBottom aria-describedby="dialog-message">
            {t(
              "erps.manageusers.model.description1",
              "You are about to permanently delete this user"
            )}
          </Typography>
          <Typography gutterBottom aria-describedby="confirmation-message">
            <strong>
              {t(
                "erps.manageusers.model.description2",
                "Are you sure you want to continue?"
              )}
            </strong>
          </Typography>
        </>
      );
    } else if (openDialogForRadio) {
      return (
        <Typography gutterBottom aria-describedby="dialog-message">
          {fiNumberValue == 10
            ? t("erps.manageusers.pendingUser.heading.dialog.radiobutton.cibc")
            : fiNumberValue == 2
            ? t("erps.manageusers.pendingUser.heading.dialog.email.bns")
            : t("erps.manageusers.pendingUser.heading.dialog.radiobutton")}
        </Typography>
      );
    } else if (openDialogForEmail) {
      return (
        <Typography gutterBottom aria-describedby="dialog-message">
          {t("erps.manageusers.pendingUser.heading.dialog.email.bns")}
        </Typography>
      );
    }
  };
  const getDialogActions = () => {
    if (open) {
      return (
        <>
          <ERPSBaseButton
            variant="secondary"
            className="button_with_zeromargin"
            onClick={handleClose}
            label={t("erps.newuser.button.cancel", "Cancel")}
          />
          <ERPSBaseButton
            variant="primary"
            className="button_with_zeromargin"
            onClick={handleDeleteUser}
            label={t("erps.newuser.button.deleteuser")}
            loading={saveLoading}
          />
        </>
      );
    } else if (openDialogForRadio) {
      return (
        <ERPSBaseButton
          variant="primary"
          className="button_with_zeromargin"
          onClick={() => {
            if (openDialogForRadio?.key) {
              setValues((preValues) => ({
                ...preValues,
                [openDialogForRadio?.key]: openDialogForRadio?.value,
              }));
            }

            setOpenDialogForRadio(false);
          }}
          label={t("erps.newuser.button.ok")}
        />
      );
    } else if (openDialogForEmail) {
      return (
        <ERPSBaseButton
          variant="primary"
          className="button_with_zeromargin"
          onClick={() => {
            if (openDialogForEmail?.key) {
              setValues((preValues) => ({
                ...preValues,
                [openDialogForEmail?.key]: openDialogForEmail?.value,
              }));
            }
            setOpenDialogForEmail(false);
          }}
          label={t("erps.newuser.button.ok")}
        />
      );
    }
  };
  const checkEmailNotificationEnable = (emailNotificationValue) => {
    if (
      roleId == wpBillerUser &&
      (props.pageName === "manage-biller" ||
        props.pageName === "account-settings" ||
        props.pageName === "manage-users") &&
      (getBillerInfo()?.emailNotificationOk == "N" ||
        getBillerInfo()?.email_notification_ok == "N")
    ) {
      return true;
    } else if (
      emailNotificationValue === "N" &&
      user?.role?.role_id == wpBillerUser &&
      props.pageName !== "account-settings"
    ) {
      return true;
    }
    return false;
  };

  const getCheckboxEmailValue = () => {
    const updatedEmailNotificationValue =
      getBillerInfo()?.emailNotificationOk ||
      getBillerInfo()?.email_notification_ok;
    if (
      roleId == wpBillerUser &&
      (props.pageName == "manage-biller" ||
        props.pageName == "account-settings") &&
      updatedEmailNotificationValue == "N"
    ) {
      return false;
    } else {
      return (values?.emailNotificationOk || updatedEmailNotificationValue) ==
        "Y"
        ? true
        : false;
    }
  };

  const errorMessageResponse = errorMessage.length
    ? errorMessage
    : validateUser();
  const fiName = localStorage.getItem("fiName");

  const getBillerId = (data) => {
    if (data?.billerId || data?.biller_id) {
      let prefixBillerId;
      if (fiNumber == 99) {
        prefixBillerId = values?.userID?.slice(0, 8);
      } else {
        if (data?.bmoBillerId || data?.bmo_biller_id) {
          prefixBillerId = data?.bmoBillerId || data?.bmo_biller_id;
        } else {
          const billerFiVal = data?.billerNonVolatileDto?.biller_fi_number;
          prefixBillerId =
            billerFiVal || data?.billerFiNumber || data?.biller_fi_number;
        }
      }

      return prefixBillerId
        ? `${prefixBillerId}-${data.billerId || data?.biller_id}`
        : data.billerId || data?.biller_id;
    } else if (data?.fi_biller_with_biller_id) {
      return data?.fi_biller_with_biller_id;
    }
  };
  const getFormatFiNumber = (fi) => {
    if (fi < 10) {
      return `00${fi}`;
    } else if (fi > 9 && fi < 100) {
      return `00${fi}`;
    }
    return fi;
  };

  const getFormatTransitNumber = (transitValue) => {
    const transitValLen = transitValue?.toString()?.length;
    if (transitValLen === 5) {
      const updatedTransitNumber = transitValue?.toString().slice(1, 4);
      return `*${updatedTransitNumber}*`;
    } else if (transitValLen > 0 && transitValLen < 5) {
      const numberOfZeros = 5 - transitValLen;
      const updatedVal = new Array(numberOfZeros).fill(0).join("");
      const updatedTransitNumber = `${updatedVal}${transitValue}`.slice(1, 4);
      return `*${updatedTransitNumber}*`;
    }
  };

  const getFiAccountNumber = (accountNumber) => {
    const slicedAccountNumberLength = accountNumber?.toString()?.length - 3;
    const maskedValue =
      slicedAccountNumberLength && slicedAccountNumberLength >= 0
        ? new Array(slicedAccountNumberLength).fill("*").join("")
        : "";
    const slicedAccountNumber = accountNumber
      ?.toString()
      .slice(slicedAccountNumberLength);
    return `${maskedValue}${slicedAccountNumber}`;
  };

  const getFormatAccountNumber = ({
    fiNumber,
    transitNumber,
    accountNumber,
  }) => {
    const formatFiNumber = getFormatFiNumber(fiNumber);
    const formatTransitNumber = getFormatTransitNumber(transitNumber);
    const formatAccountNumber = getFiAccountNumber(accountNumber);

    return `${formatFiNumber}-${formatTransitNumber}-${formatAccountNumber}`;
  };

  const hasBillerAdminOrBillerUserRole = () =>
    values?.roleId == 3 || values?.roleId == 4;

  const hasBillerAdminRole = user?.role?.role_id == 3;
  const hasBillerUserRole = user?.role?.role_id == 4;

  const hasServiceAgreementVisible = () => {
    if (props.pageName === "account-settings" && !hasBillerUserRole) {
      if (
        user?.billerNonVolatileDto?.agreement_accepted_date > 0 &&
        user?.billerNonVolatileDto?.agreement_accepted != "N"
      ) {
        return true;
      }
    } else if (props.pageName !== "account-settings" && !hasBillerAdminRole) {
      if (
        billerNonVolatileDto?.agreement_accepted_date > 0 &&
        billerNonVolatileDto?.agreement_accepted != "N"
      ) {
        return true;
      }
    }

    return false;
  };

  const renderBillerSection = () => {
    let data = getBillerInfo();

    if (data) {
      return (
        <Box>
          <Stack direction="column" style={{ marginTop: "4rem" }}>
            <Typography variant="h2">
              {t("erps.billerregistration.label.billing", "Billing")}
            </Typography>
          </Stack>

          <Stack direction="row" spacing={3}>
            <div style={{ width: "210px", padding: "20px 0px 0px 0px" }}>
              <Typography variant="body1">
                {t("erps.billerregistration.label.billerId", "Billing ID")} :
                <br />
              </Typography>

              <label className="test-label">
                {data ? getBillerId(data) : ""}
              </label>
            </div>

            <div style={{ width: "210px", padding: "20px 0px 0px 0px" }}>
              <Typography variant="body1">
                {t("erps.billerregistration.label.billerName", "Biller Name")} :
                <br />
              </Typography>

              <label className="test-label">
                {data?.biller_name || billerProfileData?.billerName}
              </label>
            </div>
          </Stack>

          {props.pageName !== "account-settings" && !hasBillerAdminRole && (
            <>
              <Stack direction="row" spacing={3}>
                <div style={{ width: "210px", padding: "20px 0px 0px 0px" }}>
                  <Typography variant="body1">
                    {t(
                      "erps.billerregistration.label.billerAccount",
                      "Billing account"
                    )}{" "}
                    :<br />
                  </Typography>
                  <label className="test-label">
                    {getFormatAccountNumber({
                      accountNumber:
                        data.debitAccountNumber || data?.debit_account_number,
                      transitNumber:
                        data.debitTransitNumber || data?.debit_transit_number,
                      fiNumber: data.debitFiNumber || data?.debit_fi_number,
                    })}
                  </label>
                </div>
                <div style={{ width: "210px", padding: "20px 0px 0px 0px" }}>
                  <Typography variant="body1">
                    {t(
                      "erps.billerregistration.label.creditingAccount",
                      "Crediting account"
                    )}{" "}
                    :<br />
                  </Typography>

                  <label className="test-label">
                    {getFormatAccountNumber({
                      accountNumber:
                        data?.credit_account_number ||
                        billerProfileData?.creditorAccountNumber,
                      transitNumber:
                        data?.credit_transit_number ||
                        billerProfileData?.creditorTransitNumber,
                      fiNumber:
                        data?.credit_fi_number ||
                        billerProfileData?.creditorFiNumber,
                    })}
                  </label>
                </div>
              </Stack>
            </>
          )}

          {props.pageName === "account-settings" && !hasBillerUserRole && (
            <>
              <Stack direction="row" spacing={3}>
                <div style={{ width: "210px", padding: "20px 0px 0px 0px" }}>
                  <Typography variant="body1">
                    {t(
                      "erps.billerregistration.label.billerAccount",
                      "Billing account"
                    )}{" "}
                    :<br />
                  </Typography>
                  <label className="test-label">
                    {getFormatAccountNumber({
                      accountNumber:
                        data.debitAccountNumber || data?.debit_account_number,
                      transitNumber:
                        data.debitTransitNumber || data?.debit_transit_number,
                      fiNumber: data.debitFiNumber || data?.debit_fi_number,
                    })}
                  </label>
                </div>
                <div style={{ width: "210px", padding: "20px 0px 0px 0px" }}>
                  <Typography variant="body1">
                    {t(
                      "erps.billerregistration.label.creditingAccount",
                      "Crediting account"
                    )}{" "}
                    :<br />
                  </Typography>

                  <label className="test-label">
                    {getFormatAccountNumber({
                      accountNumber:
                        data?.credit_account_number ||
                        billerProfileData?.creditorAccountNumber,
                      transitNumber:
                        data?.credit_transit_number ||
                        billerProfileData?.creditorTransitNumber,
                      fiNumber:
                        data?.credit_fi_number ||
                        billerProfileData?.creditorFiNumber,
                    })}
                  </label>
                </div>
              </Stack>
            </>
          )}

          <Stack mt={2} direction="row" spacing={3}>
            <>
              {(props.pageName === "account-settings" ||
                (props.pageName !== "account-settings" &&
                  !hasBillerAdminRole &&
                  fiNumberValue != 10 &&
                  fiNumberValue != 2) ||
                (props.pageName !== "account-settings" &&
                  (fiNumberValue == 10 || fiNumberValue == 2))) && (
                <ERPSBaseCheckBoxGrp
                  name="email_notification"
                  value={getCheckboxEmailValue()}
                  label={t(
                    "erps.billerregistration.label.sendNotification",
                    "Send email notification"
                  )}
                  onChange={(e) => handleChange(e, "emailNotificationOk")}
                  disabled={checkEmailNotificationEnable(
                    getEmailNotificationValue()
                  )}
                />
              )}
            </>

            {hasServiceAgreementVisible() && (
              <div style={{ width: "400px", padding: "10px 0px 0px 20px" }}>
                <Typography variant="body1">
                  {t(
                    "erps.billerregistration.label.dateAgreementAccepted",
                    "Date agreement accepted"
                  )}{" "}
                  :<br />
                </Typography>

                <label className="test-label">
                  {updatedDate(
                    props.pageName !== "account-settings"
                      ? billerNonVolatileDto?.agreement_accepted_date
                      : user?.billerNonVolatileDto?.agreement_accepted_date
                  )}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <ERPSBaseLink
                    className="link__blue"
                    target="_blank"
                    label={t("erps.billerregistration.label.viewAgreement")}
                    to={getPdfFile()}
                  />
                </label>
              </div>
            )}
          </Stack>
          <Stack mt={4} direction="column">
            {props.pageName !== "account-settings" ? (
              <ERPSBaseRadioGroup
                name="csvAdviceOk"
                value={
                  values?.csvAdviceOk || billerNonVolatileDto?.csv_advice_ok
                }
                label={t("erps.billerregistration.label.documentFormat")}
                onChange={(e) => handleChange(e, "csvAdviceOk")}
                items={documentFormatItems}
                disabled={values?.roleId == "4"}
              />
            ) : (
              <ERPSBaseRadioGroup
                name="csvAdviceOk"
                value={
                  values?.csvAdviceOk ||
                  user?.billerNonVolatileDto?.csv_advice_ok ||
                  "N"
                }
                label={t("erps.billerregistration.label.documentFormat")}
                onChange={(e) => handleChange(e, "csvAdviceOk")}
                items={documentFormatItems}
                disabled={values?.roleId == "4"}
              />
            )}
          </Stack>
        </Box>
      );
    }
  };
  return (
    <Box>
      {errorMessageResponse.length ? (
        <Box className={"manageUsers__errorMsg__container"} role="alert" mb={2}>
          <ErrorMessage error={errorMessageResponse} errorRef={errorRef} />
        </Box>
      ) : (
        ""
      )}
      {hasShowWarningMessage && (
        <Stack sx={{ width: "100%" }} spacing={2} tabIndex={0} ref={warningRef}>
          <Alert severity="warning">
            {t("erps.billermanagement.warning.nothinghaschanged")}
          </Alert>
        </Stack>
      )}

      <Box>
        <Typography variant="h2">
          {t("erps.accountsettings.label", "Account settings")}
        </Typography>
      </Box>

      {props?.keycloakUserStatus == false && (
        <Stack direction="row" spacing={3} style={{ marginTop: "1rem" }}>
          <Typography variant="h4" style={{ color: "red" }}>
            {t("erps.newuser.label.tempLockMessage")}
          </Typography>
        </Stack>
      )}

      <Box component="form" autoComplete="off">
        <Stack direction="row" spacing={3}>
          <div style={{ width: "210px", padding: "20px 0px 0px 0px" }}>
            <Typography variant="body1">
              {t("erps.manageusers.label.userid", "User ID")}
              <br />
            </Typography>

            <label className="test-label">{values.userID}</label>
          </div>
        </Stack>
        <Stack direction="row" spacing={3}>
          <ERPSBaseTextField
            id="roleId"
            type="text"
            select={true}
            label={t("erps.newuser.label.role", "Role")}
            className="common__textField__width"
            value={values.roleId}
            onChange={(e) => handleChange(e, "roleId")}
            size="small"
            required="true"
            items={userRoleListDropdown}
            isDisabled={
              props.pageName === "account-settings" ||
              props.pageName === "account-registration" ||
              hasFIAdminUser ||
              hasBillerAdminOrBillerUserRole() ||
              loginUser?.role?.role_id == 3
            }
            SelectProps={{ native: true }}
            InputLabelProps={{ shrink: true }}
            actionType="hasRoleDropDown"
            componentAccess={userProfileAccess?.childComponents}
            error={values?.roleIdErrorHighlight}
          />
          {showSubscribingEntity() && (
            <ERPSBaseTextField
              id="subscribeEntityId"
              type="text"
              select={true}
              label={t(
                "erps.newuser.label.subscribingentityid",
                "Subscribing entity id"
              )}
              className="common__textField__width"
              size="small"
              items={subscribeEntityList}
              onChange={(e) => handleChange(e, "fiCompanyNumber")}
              value={values.fiCompanyNumber}
              isDisabled={loginUser?.role?.role_id == 9 ? true : false}
              SelectProps={{ native: true }}
              InputLabelProps={{ shrink: true }}
              actionType="hasSubsciptionEntitiesDropDown"
              componentAccess={userProfileAccess?.childComponents}
            />
          )}
        </Stack>
        <Stack direction="row" spacing={3}>
          <ERPSBaseTextField
            id="firstname"
            type="text"
            error={values.firstNameErrorHighlight}
            label={t("erps.newuser.label.firstname", "First name")}
            className="common__textField__width"
            size="small"
            value={values.firstname}
            onChange={(e) => handleChange(e, "firstname")}
            required="true"
            actionType="hasFNameTextField"
            componentAccess={userProfileAccess?.childComponents}
          />
          <ERPSBaseTextField
            id="lastName"
            type="text"
            error={values.lastNameErrorHighlight}
            label={t("erps.newuser.label.lastname", "Last name")}
            className="common__textField__width"
            size="small"
            value={values.lastname}
            onChange={(e) => handleChange(e, "lastname")}
            required="true"
            actionType="hasLNameTextField"
            componentAccess={userProfileAccess?.childComponents}
          />
        </Stack>
        <Stack direction="row" spacing={3}>
          <ERPSBaseTextField
            id="emailAddress"
            type="text"
            error={values.emailAddressHighlight || emailHighlight || errorMessage === t("erps.manageusers.error.duplicate.emailaddress") }
            label={t("erps.newuser.label.emailaddress", "Email Address")}
            className="common__textField__width"
            size="small"
            value={values.emailAddress}
            onChange={(e) => handleChange(e, "emailAddress")}
            required="true"
            actionType="hasEmailTextField"
            componentAccess={userProfileAccess?.childComponents}
          />
          <ERPSBaseTextField
            id="phone"
            type="text"
            error={values.phoneErrorHighlight}
            label={t("erps.newuser.label.phonenumber", "Phone number")}
            className="common__textField__width"
            size="small"
            value={values.phone}
            onChange={(e) => handleChange(e, "phone")}
            actionType="hasPhoneTextField"
            componentAccess={userProfileAccess?.childComponents}
          />
        </Stack>

        <Stack direction="row" spacing={3}>
          <ERPSBaseRadioGroup
            name="languageCode"
            value={values.languageCode}
            label={t("erps.userprofile.language.text", "Language")}
            onChange={(e) => handleChange(e, "languageCode")}
            items={languageItems}
            actionType="hasLangDropDown"
            componentAccess={userProfileAccess?.childComponents}
          />

          <ERPSBaseTextField
            id="status"
            type="text"
            select={true}
            label={t("erps.newuser.label.status", "Status")}
            className="common__textField__width"
            size="small"
            value={values.status}
            onChange={(e) => handleChange(e, "status")}
            isDisabled={
              props.pageName === "account-settings" ||
              props.pageName === "account-registration"
            }
            SelectProps={{ native: true }}
            InputLabelProps={{ shrink: true }}
            items={statusList}
            actionType="hasStatusDropDown"
            componentAccess={userProfileAccess?.childComponents}
          />
        </Stack>
        {props.pageName == "account-settings" && (
          <Stack direction="row" spacing={3}>
            <ERPSBaseTextField
              id="sessionTimeOut"
              type="number"
              error={values.sessionTimeOutErrorHighlight}
              label={t("erps.newuser.label.sessionTimeOut")}
              className="common__textField__width"
              size="small"
              value={values.sessionTimeOut}
              onChange={(e) => handleChange(e, "sessionTimeOut")}
              // actionType="hasPhoneTextField"
              // componentAccess={userProfileAccess?.childComponents}
            />
          </Stack>
        )}

        <Stack direction="column" style={{ marginTop: "3rem" }}>
          <Typography variant="h2">
            {t("erps.newuser.label.challengeques", "Challenge question")}
          </Typography>

          <Typography variant="body1" style={{ marginTop: "1rem" }}>
            {t(
              "erps.newuser.label.challengetext",
              "The challenge question and response are used to help securely identify you if you call support."
            )}
          </Typography>
        </Stack>

        <Stack direction="column" style={{ marginTop: "1rem" }}>
          <ERPSBaseTextField
            id="challengeques"
            type="text"
            select={true}
            label={t("erps.newuser.label.challengeques", "Challenge question")}
            className="three_column__textField"
            required="true"
            size="small"
            items={challengeQuesList}
            value={values.challengeNumber}
            onChange={(e) => handleChange(e, "challengeNumber")}
            SelectProps={{ native: true }}
            InputLabelProps={{ shrink: true }}
            actionType="hasChallengeInfo"
            componentAccess={userProfileAccess?.childComponents}
            isDisabled={
              props.pageName === "manage-users" ||
              props.pageName === "manage-biller"
            }
            error={values.challengeNumberErrorHighlight}
          />
          <ERPSBaseTextField
            id="challengeresponse"
            type="text"
            error={values.challangeResponseErrorHighlight}
            label={t(
              "erps.newuser.label.challengeresponse",
              "Challenge response"
            )}
            className="three_column__textField"
            required="true"
            size="small"
            onChange={(e) => handleChange(e, "challengeResponse")}
            value={values.challengeResponse}
            actionType="hasChallengeInfo"
            componentAccess={userProfileAccess?.childComponents}
            isDisabled={
              props.pageName === "manage-users" ||
              props.pageName === "manage-biller"
            }
          />
        </Stack>

        {renderBillerSection()}
        {props.pageName !== "account-registration" && (
          <Stack direction="row" spacing={2}>
            <ERPSBaseButton
              variant="secondary"
              className="button_with_margin48"
              label={t("erps.manageusers.button.reset", "Reset")}
              onClick={navigationToMangerUserPage}
              actionType={
                billerUserProfileAccess?.childComponents
                  ? "hasCancelBUserPrfAccess"
                  : "hasCancelBtn"
              }
              componentAccess={
                billerUserProfileAccess?.childComponents ||
                userProfileAccess?.childComponents
              }
            />
            {(props.pageName === "manage-users" ||
              props.pageName === "manage-biller") && (
              <ERPSBaseButton
                variant="secondary"
                className="button_with_margin48"
                onClick={handleOpen}
                label={t("erps.userdetails.tab.link", "Delete User Profile")}
                actionType={
                  billerUserProfileAccess?.childComponents
                    ? "hasDelBillerManageUserAccess"
                    : "hasDelUserProfileAccess"
                }
                componentAccess={
                  billerUserProfileAccess?.childComponents ||
                  userProfileAccess?.childComponents
                }
              />
            )}
            <ERPSBaseButton
              variant="primary"
              className="button_with_margin48"
              label={t("erps.newuser.button.save", "Save")}
              onClick={handleSave}
              loading={dialogLoading}
              actionType={
                billerUserProfileAccess?.childComponents
                  ? "hasUpdateBUserPrfAccess"
                  : "hasUpdateUserAccess"
              }
              componentAccess={
                billerUserProfileAccess?.childComponents ||
                userProfileAccess?.childComponents
              }
            />
          </Stack>
        )}
      </Box>

      <div>
        <ERPSBaseDialog
          role="dialog"
          onClose={handleClose}
          aria-labelledby={
            openDialogForRadio?.isOpen
              ? "document-format-dialog"
              : "delete-profile-dialog"
          }
          open={
            open || openDialogForRadio?.isOpen || openDialogForEmail?.isOpen
          }
        >
          <DialogHeader
            id="delete-profile-dialog"
            onClose={handleClose}
            style={{
              fontFamily: "Roboto,Regular",
              fontSize: "18px",
              color: "#2a2c2e",
            }}
          >
            {getDialogHeader()}
          </DialogHeader>
          <DialogContent>{getDialogContent()}</DialogContent>
          <DialogActions>{getDialogActions()}</DialogActions>
        </ERPSBaseDialog>
      </div>
    </Box>
  );
};

export default UserProfile;
