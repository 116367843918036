import React, { useState, useRef, useEffect } from "react";
import { withSidebar } from "../hoc/withSidebar";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ERPSBaseTextField from "../custom/inputs/ERPSBaseTextField";
import Stack from "@mui/material/Stack";
import ERPSBaseButton from "../custom/inputs/ERPSBaseButton";
import Typography from "@mui/material/Typography";
import ERPSBaseRadioGroup from "../custom/inputs/ERPSBaseRadioGroup";
import ERPSBaseDatePicker from "../custom/inputs/ERPSDatePicker";
import ERPSGrid from "../custom/containers/ERPSGrid";
import {
  recallPaymentCustomerHeading,
} from "../../constants/RecallPaymentCustomerConstant";
import ErrorMessage from "../custom/containers/ErrorMessage";
import actions from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import CardList from "../custom/containers/cardList";
import CommonContainerBox from "../custom/containers/CommonContainerBox";
import Alert from "@mui/material/Alert";

const RecallPaymentCustomer = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    history,
    location: { pathname, state },
  } = props;

  const recallPaymentCustomerResponse = useSelector(
    (state) => state.recallPaymentCustomerReducer
  );

  const user = useSelector((state) => state.loginUserReducer.user);
  const loggedInBillerId = user?.billerNonVolatileDto?.biller_id;
  const internalBMOUserId = user?.internalBMOUserId;
  const [validationErrors, setValidationErrors] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const errorRef = useRef();
  const [highlightError, setHighlightError] = useState({});
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(false);
  const [prevBtnLoading, setPrevBtnLoading] = useState(false);
  const [nextBtnLoading, setNextBtnLoading] = useState(false);
  const [pageNumber, setPage] = useState(0);
  const [rowPerPage, setRowPerPage] = useState(100);
  const [rpcData, setRpcData] = useState(null);
  const alertRef = useRef(null);

  const selectedFiNumber = useSelector((state) => state.fiReducer?.selectedFi);
  const fiNumberValue = selectedFiNumber ? selectedFiNumber : user?.fiNumber;


  //Initial payment date is todays date
  const initialPaymentDate = new Date();
  //Function for getting min date
  const getMinDateValue = (date, days) => {
    const minDateVal = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() - days + 1
    );
    return minDateVal;
  };
  //Min date is 90 dates from today, param set to 90
  const MIN_DATE_VALUE = getMinDateValue(initialPaymentDate, 90);
  //Max date is todays date
  const MAX_DATE_VALUE = new Date();
  //Biller details info for radio buttons
  const billerDetails = [
    {
      name: "Biller ID",
      value: "billerId",
    },
    { name: "Biller name", 
      value: "billerName" 
    },
  ];

  const sortDetails = [
    { 
      name: t("erps.transactionEnquiry.page.effectivedate"), 
      value: "ED" 
    },
  ];



  /*This hook will update the errorRef focus based on state changes on the validationsErrors and errorMessage
  */
  useEffect(() => {
    if (errorRef.current) {
      errorRef.current.focus();
    }
  }, [validationErrors.length, errorMessage]);

  useEffect(() => {
    if (alertRef.current && rpcData?.length > 100) {
      alertRef.current.focus();
    }
  }, [rpcData && rpcData?.length > 100]);

  useEffect(() => {
    if (recallPaymentCustomerResponse && !state?.fromDetailPage) {
      dispatch({
        type: "GET_RPC_SUCCESS",
        data: { data: null, rpcDetails: null },
      });
    }
  }, []);

  useEffect(() => {
    if (recallPaymentCustomerResponse) {
      const recallPaymentCustomerRes =
      recallPaymentCustomerResponse?.rpcData?.data;
      if (recallPaymentCustomerRes && recallPaymentCustomerRes?.length) {
        const updatedRes = recallPaymentCustomerRes?.map((d, idx) => ({
          ...d,
          id: idx,
        }));
        setRpcData(updatedRes);
      } else {
        setRpcData(recallPaymentCustomerRes);
      }
    }
    if (
      state?.fromDetailPage &&
      recallPaymentCustomerResponse?.rpcDetails
    ) {
      const data = getPaginationProps(
        recallPaymentCustomerResponse?.rpcDetails
      );
      const page = data?.pageNumber || pageNumber;
      const row = data?.rowPerPage || rowPerPage;
      setPage(page);
      setRowPerPage(row);
      setSearchValue(recallPaymentCustomerResponse?.rpcDetails);

      if (state?.isRecalled) {
        const searchRequest = {
            ...recallPaymentCustomerResponse?.rpcDetails,
            transactionIsRejected: "Y",
            startOffset: page * row,
            endOffset: page * row + row,
            count: 0,
        };

        setInitialLoading(true);
        if (errorMessage) {
            setErrorMessage(null);
        }

        state.isRecalled = false;

        dispatch(
            actions.recallPaymentCustomerActions.getRPC(searchRequest, {...props, t, setLoading: setInitialLoading,fiNumberValue, setErrorMessage})
        );
    }
    }
  }, [recallPaymentCustomerResponse]);


  //Definning state for the search values used
  //to update these values we call setSearchValue
  //to access these values we use searchValues
  /*
    IMPORTANT!!
    There are extra values in here not used, this is due to using same DTO from TransactionEnquiry which has different values
    The values we do not need are set to null
    transactionToAmmount = paymentAmount
    paymentToDate = paymentDate
  */
  const [searchValues, setSearchValue] = useState({
    paymentFromDate: null,
    paymentToDate: initialPaymentDate,
    searchBy: "billerId",
    startOffset: 0,
    endOffset: 100,
    sortBy: "Biller ID",
    transactionToAmount: "",
    transactionFromAmount: null,
    verificationNumber: "",
    billerId: "",
    billerName: "",
    fiCustomerNumber: "",
    fiCustomerName: "",
    billerCustomerNumber: "",
    minStartDateValue: MIN_DATE_VALUE,
    maxDateValue: MAX_DATE_VALUE,
    requestFrom: "recallPaymentForCustomers"
  });

  const getPaginationProps = (data) => {
    if (state?.fromDetailPage && data) {
      const { startOffset, endOffset } = data;
      const rowPerPage = endOffset - startOffset;
      const pageNumber = endOffset / (endOffset - startOffset) - 1;
      return { rowPerPage, pageNumber };
    }
    return "";
  };


  //Cleaning inputs for requests
  const sanitizeReqBody = (reqBody) => {
    let updatedReqBody;
    Object.keys(reqBody).forEach((key) => {
      if (
        reqBody[key] === undefined ||
        ("string" === typeof reqBody[key] && reqBody[key].trim() === "")
      ) {
        delete reqBody[key];
      } else {
        if (typeof reqBody[key] === "string") {
          updatedReqBody = { ...updatedReqBody, [key]: reqBody[key].trim() };
        } else {
          updatedReqBody = { ...updatedReqBody, [key]: reqBody[key] };
        }
      }
    });

    return updatedReqBody;
  };

  const isCharOccurringOnce = (str, char) => {
    let occurrences = str?.split(char)?.length;
    if (typeof occurrences === "number") {
      occurrences -= 1;
      return occurrences === 1;
    }
  };
  //validating the search values and displaying error messages
  const validateSearchValues = (searchReqValues) => {
    const {
      paymentToDate,
      transactionToAmount,
      verificationNumber,
      billerId,
      billerName,
      fiCustomerNumber,
      fiCustomerName,
    } = searchReqValues;
    const parsedPaymentAmount = transactionToAmount
      ? parseFloat(transactionToAmount)
      : undefined;
    let errors = [];
    let highlightErrorV1 = {};
    const decimalRegEx = /^\d*(\.\d{0,2})*$/;
    const alphaNumericRegEx = /^[0-9A-Za-z\s]+$/;
    const alphaNumericWithHypenRegEx = /^[0-9A-Za-z-%\s]+$/;
    const billerNameRegex =
      /^[!@#$%^&*()`~\-_+=\[\]{}\\|;:'",<.>\?éàèùçâêîôûëïüÉÀÈÙÇÂÊÎÔÛËÏÜA-Za-z0-9\/ ]+$/;
    const billerNameWildCharRegex =
      /^(?:%?[!@#$%^&*()`~\-_+=\[\]{}\\|;:'",<.>\?éàèùçâêîôûëïüÉÀÈÙÇÂÊÎÔÛËÏÜA-Za-z0-9\/ ]+%?)$/;

    if (!paymentToDate) {
      errors.push(
        t("erps.recallpaymentcustomers.page.errorMessage.paymentDate.required")
      );
      highlightErrorV1 = { ...highlightErrorV1, paymentDate: true };
    }
    if (!transactionToAmount) {
      errors.push(
        t("erps.recallpaymentcustomers.page.errorMessage.paymentAmount.required")
      );
      highlightErrorV1 = { ...highlightErrorV1, paymentAmount: true };
    }
    if (!fiCustomerNumber) {
      errors.push(
        t("erps.recallpaymentcustomers.page.errorMessage.customerAccountNumber.required")
      );
      highlightErrorV1 = { ...highlightErrorV1, customerAccountNumber: true };
    }
    if (paymentToDate == "Invalid date") {
      errors.push(
        t("erps.transactionEnquiry.page.errorMessage.paymentFromDate.invalid")
      );
    }
    if (parsedPaymentAmount !== undefined) {
      if (parsedPaymentAmount < 0) {
        errors.push(
          t(
            "erps.transactionEnquiry.page.errorMessage.paymentFromAmountGreaterThan0"
          )
        );
        highlightErrorV1 = { ...highlightErrorV1, paymentAmount: true };
      }
      if (
        parsedPaymentAmount > 0 &&
        !decimalRegEx.test(parsedPaymentAmount)
      ) {
        errors.push(
          t("erps.transactionEnquiry.page.errorMessage.paymentFromDecimalValue")
        );
        highlightErrorV1 = { ...highlightErrorV1, paymentAmount: true };
      }
    }
    if (
      verificationNumber &&
      !alphaNumericWithHypenRegEx.test(verificationNumber)
    ) {
      errors.push(
        t(
          "erps.transactionEnquiry.page.errorMessage.confirmationNumber.invalid"
        )
      );
      highlightErrorV1 = { ...highlightErrorV1, verificationNumber: true };
    }

    if (fiCustomerNumber && !alphaNumericRegEx.test(fiCustomerNumber)) {
      errors.push(
        t(
          "erps.transactionEnquiry.page.errorMessage.customerAccountNumber.invalid"
        )
      );
      highlightErrorV1 = { ...highlightErrorV1, customerAccountNumber: true };
    }

    if (fiCustomerName && !alphaNumericWithHypenRegEx.test(fiCustomerName)) {
      errors.push(
        t("erps.transactionEnquiry.page.errorMessage.customerName.invalid")
      );
      highlightErrorV1 = { ...highlightErrorV1, customerName: true };
    }

    if (billerId && !alphaNumericRegEx.test(billerId)) {
      errors.push(
        t("erps.transactionEnquiry.page.errorMessage.billerId.invalid")
      );
      highlightErrorV1 = { ...highlightErrorV1, billerId: true };
    }

    if (billerName && !billerNameRegex.test(billerName)) {
      errors.push(
        t("erps.transactionEnquiry.page.errorMessage.billerName.invalid")
      );
      highlightErrorV1 = { ...highlightErrorV1, billerName: true };
    } else if (
      billerName &&
      ((billerName.includes("%") && billerName.length < 4) ||
        !billerNameWildCharRegex.test(billerName) ||
        (billerName.includes("%") && !isCharOccurringOnce(billerName, "%")))
    ) {
      errors.push(
        t("erps.transactionEnquiry.page.errorMessage.billerName.minChar")
      );
      highlightErrorV1 = { ...highlightErrorV1, billerName: true };
    }

    return { errors, highlightErrorV1 };
  };

  //using the setSearchValue hook to update our variables
  //this function is called by each of our input fields  
  const handleChange = (key, value) => {
    setSearchValue((prevState) => ({
      ...prevState,
      [key]: value,
    }));
    
  };

  const handleReset = () => {
    setHighlightError({});
    setSearchValue({
      paymentFromDate: null,
      paymentToDate: initialPaymentDate,
      searchBy: "billerId",
      startOffset: 0,
      endOffset: 100,
      sortBy: "Biller ID",
      transactionToAmount: "",
      transactionFromAmount: null,
      verificationNumber: "",
      billerId: "",
      billerName: "",
      fiCustomerNumber: "",
      fiCustomerName: "",
      billerCustomerNumber: "",
      minStartDateValue: MIN_DATE_VALUE,
      maxDateValue: MAX_DATE_VALUE,
    });
    setValidationErrors([]);
    setPage(0);
    setErrorMessage(null);
  
    dispatch({
      type: "GET_RPC_SUCCESS",
      data: { data: null, rpcDetails: null },
    });
    
  };

  const handleSearch = () => {
    const actualCriteria = sanitizeReqBody({ ...searchValues });
    const { errors, highlightErrorV1 } = validateSearchValues(actualCriteria);
    const {
      startOffset,
      sortBy,
      searchBy,
      endOffset,
      count,
      ...rest
    } = actualCriteria;
    if (errors.length) {
      setValidationErrors(errors);
      setHighlightError(highlightErrorV1);
    }  
    else {
      setValidationErrors([]);
      setHighlightError({});
      setLoading(true);
      if (pageNumber) {
        setPage(0);
      }
      if (errorMessage) {
        setErrorMessage(null);
      }

      dispatch(
        actions.recallPaymentCustomerActions.getRPC(
          {
            ...actualCriteria,
            billerId:               
              actualCriteria?.billerId ||
              searchValues?.billerId ||
              internalBMOUserId?.slice(0, -2) ||
              loggedInBillerId,
            count: 0,
            startOffset: 0,
            endOffset: 100,
          },
          { ...props, t, history, setLoading, fiNumberValue, setErrorMessage }
        )
      );
    }
  };

  const paginateData = (values) => {
    let setBtnLoading;
    const actualCriteria = sanitizeReqBody({ ...values });
    if (actualCriteria.startOffset < actualCriteria.endOffset) {
      if (actualCriteria?.actionType === "prev") {
        setBtnLoading = setPrevBtnLoading;
        setBtnLoading(true);
      } else if (actualCriteria?.actionType === "next") {
        setBtnLoading = setNextBtnLoading;
        setBtnLoading(true);
      }
      setInitialLoading(true);
      const searchRequest = {
        ...searchValues,
        startOffset: actualCriteria.startOffset,
        endOffset: actualCriteria.endOffset,
        count: 0,
        billerId: searchValues?.billerId || loggedInBillerId,
      };
      if (errorMessage) {
        setErrorMessage(null);
      }

      dispatch(
        actions.recallPaymentCustomerActions.getRPC(
          searchRequest,
          {
            ...props,
            t,
            fiNumberValue,
            setErrorMessage,
            setLoading: setInitialLoading,
            setBtnLoading,
          },
          { ...props, t, history, setLoading, fiNumberValue, setErrorMessage }
        )
      );
    }
  };

  

  return (
    <div role="main">
      <Box ml={3}>
        {validationErrors.length || errorMessage ? (
          <Box
            className="manageUsers__errorMsg__container"
            role="alert"
            mt={validationErrors.length || errorMessage ? 2 : 0}
          >
            {validationErrors.length || errorMessage ? (
              <ErrorMessage
                error={
                  validationErrors?.length ? validationErrors : errorMessage
                }
                errorRef={errorRef}
              />
            ) : (
              ""
            )}
          </Box>
        ) : (
          ""
        )}

        <Box className="manageUsers__page__title">
          {/* Payment Recall Title*/}
          <CommonContainerBox title={t("erps.recallpaymentcustomers.page.title")} />
          <Typography variant="h1">
            {t("erps.recallpaymentcustomers.page.title")}
          </Typography>
        </Box>

        {/*Search transactions subheading*/}
        <Box mt={6}>
          <Typography variant="h2">
            {t("erps.transactionEnquiry.page.description")}
          </Typography>
        </Box>

        {/*Wildcard description*/}
        <Box mt={1}>
          <Typography variant="body1">
            {t("erps.transactionEnquiry.page.wildcardDescription")}
          </Typography>
        </Box>

        {/*Transaction Details subheading*/}
        <Box mt={2}>
          <Typography variant="h3">
            {t(
              "erps.transactionEnquiry.page.transactionDetails.page.subheading"
            )}
          </Typography>
        </Box>

        {/*Transaction Details input fields*/}
        <Stack direction="row" spacing={2}>
          {/*Payment date selector*/}
          <ERPSBaseDatePicker
            label={t("erps.recallpaymentcustomers.page.label.paymentdate", "Payment Date")}
            onChange={(e) => handleChange("paymentToDate", e)}
            value={searchValues?.paymentToDate}
            className="app_datepicker_biller_transactionEnquiry"
            datePickerStyle={"quick-reg-datepicker-v1"}
            maxDate={searchValues.maxDateValue}
            minDate={searchValues.minStartDateValue}
            error={highlightError?.paymentDate}
            required={true}
            id="recall_payment_customer_date"
          />
          {/*Payment amount input*/}
          <ERPSBaseTextField
            id="paymentAmount"
            type="number"
            allowDecimal={true}
            label={t("erps.recallpaymentcustomers.page.label.paymentamount")}
            className="transactionEnquiry__textField__width"
            size="small"
            onChange={(e) =>
              handleChange("transactionToAmount", e.target.value)
            }
            value={searchValues?.transactionToAmount}
            error={highlightError?.paymentAmount}
            required="true"
          />
          {/*Confirmation number input*/}
          <ERPSBaseTextField
            id="verificationNumber"
            type="text"
            allowDecimal={false}
            label={t("erps.recallpaymentcustomers.page.label.confirmationNumber")}
            className="transactionEnquiry__textField__width"
            size="small"
            onChange={(e) =>
              handleChange("verificationNumber", e.target.value)
            }
            value={searchValues?.verificationNumber}
            error={highlightError?.verificationNumber}
          />
        </Stack>
        {/*Biller details subheading*/}
        <Box mt={4}>
          <Typography variant="h3">
            {t(
              "erps.transactionEnquiry.componentToPrint.page.subheading.billerDetails"
            )}
          </Typography>
        </Box>

        {/*Biller details input fields*/}
        <Stack direction="row" spacing={2}>
          <Box mt={2}>
            <ERPSBaseRadioGroup
              name="searchBy"
              marginTop={"true"}
              InputLabelProps={{ shrink: true }}
              value={searchValues?.searchBy}
              label={t("erps.transactionEnquiry.page.label.searchBy")}
              onChange={(e) => handleChange("searchBy", e.target.value)}
              items={billerDetails}
              error={highlightError?.searchBy}
              labelPlacement="start"
              className={"transactionEnquiry__radioButton_direction"}
            />
          </Box>
          <Box style={{ marginTop: "-30px" }}>
            {searchValues?.searchBy === "billerId" && (
              <ERPSBaseTextField
                id="billerId"
                type="text"
                label={t("erps.transactionEnquiry.page.label.billerId")}
                className="transactionEnquiry__textField__width"
                size="small"
                onChange={(e) => handleChange("billerId", e.target.value)}
                value={searchValues?.billerId}
                error={highlightError?.billerId}
                inputProps={{ maxLength: 9 }}
              />
            )}
            {searchValues?.searchBy === "billerName" && (
              <ERPSBaseTextField
                id="billerName"
                type="text"
                label={t("erps.transactionEnquiry.page.label.billerName")}
                className="transactionEnquiry__textField__width"
                size="small"
                onChange={(e) => handleChange("billerName", e.target.value)}
                value={searchValues?.billerName}
                error={highlightError?.billerName}
                inputProps={{ maxLength: 45 }}
              />
            )}
          </Box>            
        </Stack>

        {/*Customer details subheading*/}
        <Box mt={4}>
          <Typography variant="h3">
            {t(
              "erps.transactionEnquiry.componentToPrint.page.subheading.customerDetails"
            )}
          </Typography>
        </Box>
        <Stack direction="row" spacing={2}>
          <ERPSBaseTextField
            id="billerCustomerNumber"
            type="text"
            label={t("erps.transactionEnquiry.page.label.customerFiNumber")}
            className="transactionEnquiry__textField__width"
            size="small"
            onChange={(e) =>
              handleChange("fiCustomerNumber", e.target.value)
            }
            value={searchValues?.fiCustomerNumber}
            error={highlightError?.customerAccountNumber}
            required="true"
            inputProps={{ maxLength: 45 }}

          />
          <ERPSBaseTextField
            id="fiCustomerName"
            type="text"
            label={t("erps.transactionEnquiry.page.label.customerFiName")}
            className="transactionEnquiry__textField__width"
            size="small"
            onChange={(e) => handleChange("fiCustomerName", e.target.value)}
            value={searchValues?.fiCustomerName}
            error={highlightError?.customerName}
            inputProps={{ maxLength: 30 }}
          />
        </Stack>
        {rpcData && rpcData?.length > 100 ? (
          <Stack
            sx={{ width: "100%" }}
            spacing={2}
            mt={4}
            tabIndex={0}
            ref={alertRef}
          >
            <Alert severity="info">
              {t("erps.transactionenquiry.info.message")}
            </Alert>
          </Stack>
        ) : (
          ""
        )}
        <Box display="flex">
          <ERPSBaseButton
            variant="secondary"
            className="button_with_margin48"
            label={t("erps.transactionEnquiry.page.button.reset")}
            onClick={handleReset}
          />
          <Box pl={2}>
            <ERPSBaseButton
              variant="primary"
              className="button_with_margin48"
              label={t("erps.transactionEnquiry.page.button.search")}
              onClick={handleSearch}
              loading={loading}
            />
          </Box>
        </Box>
        <Box mt={6}>
          <Typography variant="h2">
            {" "}
            {t("erps.manageusers.searchuser.searchresults")}
          </Typography>
        </Box>
        {rpcData !== null &&
        rpcData !== undefined &&
        rpcData.length > 0 ? (
          <>
            <Box className="manageUsers_table_visible">
              <ERPSGrid
                data={{
                  rows: rpcData,
                  columns: recallPaymentCustomerHeading(),
                }}
                dataGridWidth={"dataGrid__box__container__recallPaymentCustomer"}
                initialLoading={initialLoading}
                pageNumber={pageNumber}
                setPage={setPage}
                rowPerPage={rowPerPage}
                onAnyPaginationAction={paginateData}
                tableLabelText={t("erps.transactionEnquiry.page.title")}
                hideFooterPagination={true}
                pageName={"transaction-enquiry"}
                prevBtnLoading={prevBtnLoading}
                nextBtnLoading={nextBtnLoading}
              />
            </Box>
            <Box className="manageUsers_card_visible">
              <CardList
                data={{
                  rows: rpcData,
                  columns: recallPaymentCustomerHeading(),
                }}
                hideFooterPagination={true}
                pageName={"transaction-enquiry"}
                pageNumber={pageNumber}
                setPage={setPage}
                rowPerPage={rowPerPage}
                prevBtnLoading={prevBtnLoading}
                nextBtnLoading={nextBtnLoading}
                onAnyPaginationAction={paginateData}

              />
            </Box>
          </>
        ) : (
          ""
        )}
        {(rpcData === null || rpcData === undefined) && (
          <Box mt={1}>
            <Typography variant="body1">
              {" "}
              {t(
                "erps.availabledocuments.searchuser.notstarted",
                "Search not started"
              )}
            </Typography>
          </Box>
        )}
        {Array.isArray(rpcData) && rpcData?.length === 0 && (
          <Box mt={1}>
            <Typography variant="body1">
              {" "}
              {`0 ${t("erps.transactionEnquiry.page.resultfound")}`}
            </Typography>
          </Box>
        )}
      </Box>
    </div>
  );
};

export default withSidebar(RecallPaymentCustomer);
