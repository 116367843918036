const transactionEnquiryTypes = {
  GET_TRANSACTION_ENQUIRY_SUCCESS: "GET_TRANSACTION_ENQUIRY_SUCCESS",
  GET_TRANSACTION_ENQUIRY_ERROR: "GET_TRANSACTION_ENQUIRY_ERROR",
  UPDATE_RECALL_RETRACE_INFO_SUCCESS: "UPDATE_RECALL_RETRACE_INFO_SUCCESS",
  UPDATE_RECALL_RETRACE_INFO_FAILURE: "UPDATE_RECALL_RETRACE_INFO_FAILURE",
  GET_TRANSACTION_FOR_REJECT_SUCCESS: "GET_TRANSACTION_FOR_REJECT_SUCCESS",
  GET_TRANSACTION_FOR_REJECT_FAILURE: "GET_TRANSACTION_FOR_REJECT_FAILURE",
  UPDATE_TRANSACTION_FOR_REJECT_SUCCESS: "UPDATE_TRANSACTION_FOR_REJECT_SUCCESS",
  UPDATE_TRANSACTION_FOR_REJECT_FAILURE: "UPDATE_TRANSACTION_FOR_REJECT_FAILURE",
  UPDATE_TRANSACTION_FOR_RECALL_SUCCESS: "UPDATE_TRANSACTION_FOR_RECALL_SUCCESS",
  UPDATE_TRANSACTION_FOR_RECALL_FAILURE: "UPDATE_TRANSACTION_FOR_RECALL_FAILURE",
  UPDATE_TRANSACTION_FOR_PENDING_APPROVAL_SUCCESS: "UPDATE_TRANSACTION_FOR_PENDING_APPROVAL_SUCCESS",
  UPDATE_TRANSACTION_FOR_PENDING_APPROVAL_FAILURE: "UPDATE_TRANSACTION_FOR_PENDING_APPROVAL_FAILURE",

};

export default transactionEnquiryTypes;
