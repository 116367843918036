
import ERPSBaseLink from "../components/custom/inputs/ERPSBaseLink";
import { t } from "i18next";
import { convertDateIntoERPSDateFormat, formatAmount } from "../utils/helper";


// this was copied from TransactionEnquiryConstants.js
export const bmoWithWebPortal = (fiNumber, roleId) => {
  const webPortalRoleIds = [1, 2, 3, 4];
  return fiNumber == 99 && webPortalRoleIds.includes(roleId);
};

export const upcomingRecallsRejectsHeading = (componentAccess) => {
  return [
    { field: "id",
      headerName: t("erps.common.label.number.short"),
      width: 60,
      sortable: false,
      renderCell: (params) => (
        <ERPSBaseLink className="link__blue"
          to={ { pathname: `/upcoming-recalls-rejects/recall-reject-details`, state: { id: params.row?.id } }}
          label={ "" + params.row?.id } />
        ),
    },
    { field: "transaction_amount",
      headerName: t("erps.common.label.paymentAmount"),
      width: 135,
      sortable: false,
      renderCell: (params) => { if (params.value)  return formatAmount(params.value); },
    },
    { field: "effective_date",
      headerName: t("erps.common.label.effectiveDate"),
      width: 110,
      sortable: false,
      renderCell: (params) => convertDateIntoERPSDateFormat(params.value),
    },
    { field: "verification_number",
      headerName: t("erps.common.label.confirmationNumber"),
      width: 160,
      sortable: false,
    },
    { field: "fi_biller_id",
      headerName: t("erps.common.label.billerID"),
      width: 120,
      sortable: false,
    },
    { field: "fi_customer_number",
      headerName: t("erps.common.label.customerAccountNumber"),
      width: 120,
      sortable: false,
    },
    { field: "fi_customer_name",
      headerName: t("erps.common.label.customerName"),
      width: 230,
      sortable: false,
    },
    { field: "transaction_status",
      headerName: t("erps.common.label.status"),
      width: 140,
      sortable: false,
      renderCell: (params) => t("erps.common.status.toBeProcessed"),
    },
  ];
};

export const EMPTY_SEARCH_CRITERIA = {
  searchType: null,
  startOffset: 0,
  endOffset: 100,
  limit: 100,
};

